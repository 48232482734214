import React,{useContext,useEffect,useState} from 'react';
import Layout from '../../../components/Layout';
import StaffLayout from '../../../components/Layouts/StaffLayout';

import { useNavigate,useParams } from "react-router-dom";
import axiosWithInterceptor from '../../../../hooks/axiosInterceptor';
import { AuthenticationContext } from '../../../../services/AuthContextModule';
import ProfileAvatar from '../../../components/Avatar';
import { useBanner } from '../../../../services/SystemBannerMessage';
import moment from 'moment';
import StudentsGradeOptions from '../administratorComponents/StudentsGradeOptions';
import SeparatorLine from '../../schoolComponents/SeparatorLine';
import StudentCountryPicker from '../administratorComponents/StudentCountryPicker';
import 'react-datepicker/dist/react-datepicker.css';
import StudentBDayPicker from '../administratorComponents/StudentBDayPicker';
import {PencilSquareIcon, XMarkIcon,CheckIcon } from '@heroicons/react/24/outline';
import { delayLoading } from '../../../../utils/loadingDelay';

export default function AdministratorStudentProfileEdit() {
    const useAxiosWithInterceptor = axiosWithInterceptor();
    const {authState} = useContext(AuthenticationContext);

    const userId = authState.user;
    const {studentId} = useParams();
    const schoolId = authState.schoolId;

    // const location = useLocation();
    // const navigateTo = useNavigate();
    const {showBanner} = useBanner();

    const [studentInfo,setStudentInfo] = useState({});

    // form states
    const [studentBasicObj, setStudentBasicObj] = useState({
        firstName:"",
        lastName:"",
        email:"",
        gender:"",
    });

    const [originalStudentBasicObj,setOriginalStudentBasicObj] = useState({
        firstName:"",
        lastName:"",
        email:"",
        gender:"",
    });

    const [studentBasicObjEdit,setStudentBasicObjEdit] = useState(false);
    
    const toggleStudentBasicEdit = ()=>{
        setStudentBasicObjEdit(!studentBasicObjEdit);
        setStudentBasicObj({
            firstName:originalStudentBasicObj.firstName,
            lastName:originalStudentBasicObj.lastName,
            email:originalStudentBasicObj.email,
            gender: originalStudentBasicObj.gender ?? "",
        });
    }

    const handleStudentBasicChange = async (e)=>{
        const { name, value } = e.target;

        setStudentBasicObj((prev) => ({
            ...prev,
            [name]: value === "" && name === "gender" ? null : value, // Set `null` if gender is empty
        }));
    }
    const handleStudentBasicBlur = (e)=>{
        setStudentBasicObj((prev) => ({
            ...prev,
            [e.target.name]: e.target.value.trim()
        }));
    }

    const handleStudentBasicSave = async()=>{
        
        const updatedStudentBasic ={}
        Object.keys(studentBasicObj).forEach((key)=>{
            if(studentBasicObj[key]!==originalStudentBasicObj[key]){
                updatedStudentBasic[key] = studentBasicObj[key];
            }
        })
        if(Object.keys(updatedStudentBasic).length === 0){
            showBanner("No changes made to student basic information","bg-green-500");
            return;
        }
        if(updatedStudentBasic.email && !validateEmail(updatedStudentBasic.email)){
            showBanner("Invalid email address","bg-yellow-500");
            return;
        }
        await useAxiosWithInterceptor.put(`/api/user/${userId}/school/${schoolId}/administrator/students/${studentId}`,updatedStudentBasic,{withCredentials:true})
        .then((response)=>{
            if(response.status === 200){
                showBanner("Student Basic Information Updated","bg-green-500");
                getStudentInfo();
            }
        }).catch((error)=>{
            console.error(error.response);
            showBanner("Error updating student basic information");
        })
        setStudentBasicObjEdit(!studentBasicObjEdit);
    }
    
    // ------------------------ Student information ---------------------

    const [studentInfoObj,setStudentInfoObj] = useState({
        dateOfBirth:"",
        nationality:"",
    });
    const [originalStudentInfoObj,setOriginalStudentInfoObj] = useState({
        dateOfBirth:"",
        nationality:"",
    });

    const [studentInfoObjEdit,setStudentInfoObjEdit] = useState(false);
    const toggleStudentInfoEdit = ()=>{
        setStudentInfoObjEdit(!studentInfoObjEdit);
    }
    
    const handleStudentInfoSave = async()=>{
        setStudentInfoObjEdit(!studentInfoObjEdit);
        // more here axios call
        const updatedStudentInfo ={}
        Object.keys(studentInfoObj).forEach((key)=>{
            if(studentInfoObj[key]!==originalStudentInfoObj[key]){
                updatedStudentInfo[key] = studentInfoObj[key];
            }
        })
        if(Object.keys(updatedStudentInfo).length === 0){
            showBanner("No changes made to student information","bg-green-500");
            return;
        }
        
        await useAxiosWithInterceptor.put(`/api/user/${userId}/school/${schoolId}/administrator/students/${studentId}/edit`,updatedStudentInfo,{withCredentials:true})
        .then((response)=>{
            if(response.status === 200){
                showBanner("Student Information Updated","bg-green-500");
                getStudentInfo();
            }
        }).catch((error)=>{
            console.error(error);
            showBanner("Error updating student information");
        })
    }

    const handleStudentBDayChange = (newBDayDate)=>{
        setStudentInfoObj({
            ...studentInfoObj,
            dateOfBirth:newBDayDate
        });
    }

    const handleCountryChange = (selectedOption) => {
        setStudentInfoObj({
            ...studentInfoObj,
            nationality: selectedOption.value
        });
    }

    // ------------------------ Parent information ---------------------

    const [parentInfoObj,setParentInfoObj] = useState({
        fatherId:"",
        fatherFirstName:"",
        fatherLastName:"",
        fatherEmail:"",
        fatherActivated:null,
        fatherAccountEnabled:null,
        motherId:"",
        motherFirstName:"",
        motherLastName:"",
        motherEmail:"",
        motherActivated:null,
        motherAccountEnabled:null,
    });

    const [originalParentInfoObj,setOriginalParentInfoObj] = useState({
        fatherId:"",
        fatherFirstName:"",
        fatherLastName:"",
        fatherEmail:"",
        fatherActivated:null,
        fatherAccountEnabled:null,
        motherId:"",
        motherFirstName:"",
        motherLastName:"",
        motherEmail:"",
        motherActivated:null,
        motherAccountEnabled:null,
    });

    // --------- Edit Parent information ------------ //

    const [parentInfoObjEdit,setParentInfoObjEdit] = useState(false);

    const handleCancelParentInfoEdit = ()=>{
        setParentInfoObjEdit(!parentInfoObjEdit);
        setParentInfoObj({
            fatherFirstName:originalParentInfoObj.fatherFirstName,
            fatherLastName:originalParentInfoObj.fatherLastName,
            fatherEmail:originalParentInfoObj.fatherEmail,
            motherFirstName:originalParentInfoObj.motherFirstName,
            motherLastName:originalParentInfoObj.motherLastName,
            motherEmail:originalParentInfoObj.motherEmail,
        });
    }

    const handleParentInfoChange = (e)=>{
        setParentInfoObj({
            ...parentInfoObj,
            [e.target.name]:e.target.value
        });
    }

      // Trim only onBlur (when user leaves the field)
    const handleParentInfoBlur = (e) => {
        setParentInfoObj((prev) => ({
        ...prev,
        [e.target.name]: e.target.value.trim()
        }));
    };

    const handleSavingParentsInfo = async()=>{
        const updatedParentInfo ={}
        Object.keys(parentInfoObj).forEach((key)=>{
            if(parentInfoObj[key]!==originalParentInfoObj[key]){
                updatedParentInfo[key] = parentInfoObj[key];
            }
        })
        if(Object.keys(updatedParentInfo).length === 0){
            showBanner("No changes made to parent information","bg-green-500");
            setParentInfoObjEdit(!parentInfoObjEdit);
            return;
        }

        if (updatedParentInfo.fatherEmail && !validateEmail(updatedParentInfo.fatherEmail)) {
            showBanner("Invalid father email address", "bg-yellow-500");
            return;
        }
        if (updatedParentInfo.motherEmail && !validateEmail(updatedParentInfo.motherEmail)) {
        showBanner("Invalid mother email address", "bg-yellow-500");
        return;
        }

        await useAxiosWithInterceptor.put(`/api/user/${userId}/school/${schoolId}/administrator/students/${studentId}/parents/edit`,updatedParentInfo,{withCredentials:true})
        .then((response)=>{
            if(response.status === 200){
                showBanner("Parent Information Updated","bg-green-500");
                getStudentInfo();
                setParentInfoObjEdit(!parentInfoObjEdit);
            }
        }).catch((error)=>{
            // console.error(error.response.data.message);
            showBanner("Error: "+error.response.data.message,"bg-yellow-500");
        })
    }

    const handleParentEnable = async(parentInfo,id)=>{

        let findParent = {};
        if(parentInfo.fatherId && parentInfo.fatherId === id){
            findParent = {
                parentId:parentInfo.fatherId,
                parentFirstName:parentInfo.fatherFirstName,
                parentLastName:parentInfo.fatherLastName,
                parentEmail:parentInfo.fatherEmail,
            }
        } else if(parentInfo.motherId && parentInfo.motherId === id){
            findParent = {
                parentId:parentInfo.motherId,
                parentFirstName:parentInfo.motherFirstName,
                parentLastName:parentInfo.motherLastName,
                parentEmail:parentInfo.motherEmail,
            }
        } else if(parentInfo.guardianId && parentInfo.guardianId === id){
            findParent = {
                parentId:parentInfo.guardianId,
                parentFirstName:parentInfo.guardianFirstName,
                parentLastName:parentInfo.guardianLastName,
                parentEmail:parentInfo.guardianEmail,
            }
        } else {
            showBanner("Parent information invalid","bg-yellow-400");
            return;
        }

        console.log(findParent)
        // first check email is valid, then enable
        if(!findParent.parentEmail){
            showBanner("Parent email is invalid","bg-yellow-500");
            return;
        }
        if(!findParent.parentFirstName || !findParent.parentLastName){
            showBanner("Parent name is invalid","bg-yellow-500");
            return;
        }

        const isConfirmed = await customConfirm("Are you sure you want to enable this parent account?");
        if (!isConfirmed) return;

        await useAxiosWithInterceptor.put(`/api/user/${userId}/school/${schoolId}/administrator/students/${studentId}/parents/${findParent.parentId}/enable`,{findParent},{withCredentials:true})
        .then((response)=>{
            if(response.status === 200){
                showBanner("Parent account enabled","bg-green-500");
                getStudentInfo();
            }
        })
        .catch((error)=>{
            console.error(error);
            showBanner("Error enabling parent account");
        })
    }

    const handleParentDisable = async(parentInfo,id)=>{
        let findParent = {};
        if(parentInfo.fatherId && parentInfo.fatherId === id){
            findParent = {
                parentId:parentInfo.fatherId,
                parentFirstName:parentInfo.fatherFirstName,
                parentLastName:parentInfo.fatherLastName,
                parentEmail:parentInfo.fatherEmail,
            }
        } else if(parentInfo.motherId && parentInfo.motherId === id){
            findParent = {
                parentId:parentInfo.motherId,
                parentFirstName:parentInfo.motherFirstName,
                parentLastName:parentInfo.motherLastName,
                parentEmail:parentInfo.motherEmail,
            }
        } else if(parentInfo.guardianId && parentInfo.guardianId === id){
            findParent = {
                parentId:parentInfo.guardianId,
                parentFirstName:parentInfo.guardianFirstName,
                parentLastName:parentInfo.guardianLastName,
                parentEmail:parentInfo.guardianEmail,
            }
        } else {
            showBanner("Parent information invalid","bg-yellow-500");
            return;
        }

        const isConfirmed = await customConfirm("Are you sure you want to disable this parent account?");
        if (!isConfirmed) return;

        await useAxiosWithInterceptor.put(`/api/user/${userId}/school/${schoolId}/administrator/students/${studentId}/parents/${findParent.parentId}/disable`,{findParent},{withCredentials:true})
        .then((response)=>{
            if(response.status === 200){
                showBanner("Parent account disabled","bg-green-500");
                getStudentInfo();
            }
        })
        .catch((error)=>{
            console.error(error);
            showBanner("Error disabling parent account");
        })
    }

    // ------------------------ page loading ---------------------
    const getStudentInfo = async()=>{
        await useAxiosWithInterceptor.get(`/api/user/${userId}/school/${schoolId}/administrator/students/${studentId}`,{withCredentials:true})
            .then((response)=>{
                if(response.status === 200){
                    let studentBasic = response.data.payload;
                    setStudentInfo(studentBasic);
                    setStudentBasicObj({
                        firstName:studentBasic.firstName,
                        lastName:studentBasic.lastName,
                        email:studentBasic.email,
                        gender:studentBasic.gender,
                    });

                    setOriginalStudentBasicObj({
                        firstName:studentBasic.firstName,
                        lastName:studentBasic.lastName,
                        email:studentBasic.email,
                        gender:studentBasic.gender,
                    });

                    let originalStudentInfo = response.data.payload.studentInformation;

                    let parentInfo = response.data.payload.parentsInformation;

                    let motherInfo = parentInfo.find((studentParent)=>studentParent.parent.relationship === "mother");
                    let fatherInfo = parentInfo.find((studentParent)=>studentParent.parent.relationship === "father");
                    let guardianInfo = parentInfo.find((studentParent)=>studentParent.parent.relationship === "guardian");

                    setStudentInfoObj({
                        dateOfBirth:originalStudentInfo.dateOfBirth,
                        nationality:originalStudentInfo.nationality,
                    });

                    setOriginalStudentInfoObj({
                        dateOfBirth:originalStudentInfo.dateOfBirth,
                        nationality:originalStudentInfo.nationality,
                    });
                    
                    setParentInfoObj({
                        fatherId:fatherInfo?.parent?.id??null,
                        fatherFirstName:fatherInfo?.parent?.firstName??"",
                        fatherLastName:fatherInfo?.parent?.lastName??"",
                        fatherEmail:fatherInfo?.parent?.email??"",
                        fatherActivated:fatherInfo?.parent?.activated,
                        fatherAccountEnabled:fatherInfo?.parent?.accountEnabled,
                        motherId:motherInfo?.parent?.id??null,
                        motherFirstName:motherInfo?.parent?.firstName??"",
                        motherLastName:motherInfo?.parent?.lastName??"",
                        motherEmail:motherInfo?.parent?.email??"",
                        motherActivated:motherInfo?.parent?.activated,
                        motherAccountEnabled:motherInfo?.parent?.accountEnabled,
                    });
                    setOriginalParentInfoObj({
                        fatherId:fatherInfo?.parent?.id??null,
                        fatherFirstName:fatherInfo?.parent?.firstName??"",
                        fatherLastName:fatherInfo?.parent?.lastName??"",
                        fatherEmail:fatherInfo?.parent?.email??"",
                        fatherActivated:fatherInfo?.parent?.activated,
                        fatherAccountEnabled:fatherInfo?.parent?.accountEnabled,
                        motherId:motherInfo?.parent?.id??null,
                        motherFirstName:motherInfo?.parent?.firstName??"",
                        motherLastName:motherInfo?.parent?.lastName??"",
                        motherEmail:motherInfo?.parent?.email??"",
                        motherActivated:motherInfo?.parent?.activated,
                        motherAccountEnabled:motherInfo?.parent?.accountEnabled,
                    });
                }
            }).catch((error)=>{
                console.error(error)
                showBanner("Page Not found, back to main page")
                // navigateTo('/school/administrator/archive',{replace:true})
            })
    }

    // ------------------------ email validation ---------------------

    const validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailRegex.test(email);
    };

    useEffect(()=>{
        // delayLoading(getStudentInfo,2000)
        getStudentInfo();
    },[]);

    useEffect(()=>{
        document.title = studentInfo?.firstName && studentInfo?.lastName 
        ? `${studentInfo.firstName} ${studentInfo.lastName}` 
        : "student profile"
    },[studentInfo])
  
  return (
    <StaffLayout>
    <div className='w-full h-full'>
        <div className="bg-opacity-75 rounded-2xl p-2 lg:mx-2">
            <div className='flex space-x-10 w-full px-3 py-2 rounded-2xl  bg-indigo-500'>
                <div id='student-basic' className=''>
                <div className='flex justify-center'>
                    <ProfileAvatar user={studentInfo} size={20} textSize={'text-3xl'}/>
                </div>
                </div>
                <div className=''>
                <div className='my-2'>
                    <p className='font-bold text-3xl text-white'>{studentInfo?.firstName} {studentInfo?.lastName}</p>
                </div>
                <div className='flex space-x-5 items-center text-white'>
                    <p className='font-semibold text-lg'>{studentInfo?.gradeLevel && "Class of "+ calculateClassYear(studentInfo?.gradeLevel)} {studentInfo?.gradeLevel && "(Grade "+studentInfo?.gradeLevel+")"
                    }</p>
                </div>
                </div>
            </div>

            <div id='student-profile' className='rounded-lg shadow-sm my-2 space-y-2 bg-white'>
            <div className='text-xl py-2 px-3 rounded-t-md bg-slate-200 font-semibold flex justify-between'>
                <p >Basic Information</p>
                <PencilSquareIcon className='w-5 h-5 cursor-pointer' onClick={toggleStudentBasicEdit}/>
            </div>
            </div>
            {/* -------------------- basic info -------------------- */}
            <div className=' px-2 bg-white rounded'>
                <div id='column-one' className='col-span-3'>
                    <div className='flex space-x-5 items-center px-3 py-2 '>
                        <div className='flex space-x-4 w-full'>
                        { studentBasicObjEdit?
                            <div className='items-center w-full'>
                                <div>
                                    <p className='text-sm'>First Name:</p>
                                    <input 
                                        type='text' 
                                        name='firstName'
                                        value={studentBasicObj.firstName}
                                        onChange={handleStudentBasicChange}
                                        onBlur={handleStudentBasicBlur}
                                        className='border border-gray-300 rounded px-2 py-1 w-80 text-blue-500'
                                    />
                                </div>
                                <div>
                                    <p className='text-sm'>Last Name:</p>
                                    <input 
                                        type='text' 
                                        name='lastName'
                                        value={studentBasicObj.lastName}
                                        onChange={handleStudentBasicChange}
                                        onBlur={handleStudentBasicBlur}
                                        className='border border-gray-300 rounded px-2 py-1 w-80 text-blue-500'
                                    />
                                </div>
                                <div>
                                    <p className='text-sm'>Gender</p>
                                    <select
                                        className="border border-gray-300 rounded px-2 py-1 w-80 text-blue-500"
                                        name="gender"
                                        value={studentBasicObj.gender ?? ""} // Default to "" if gender is null or undefined
                                        onChange={handleStudentBasicChange}
                                    >
                                        <option value="">Select gender</option> 
                                        <option value="male">male</option>
                                        <option value="female">female</option>
                                        <option value="other">other</option>
                                    </select>
                                </div>
                                <p className='text-sm'>Email:</p>
                                <div className='space-x-2 flex items-center'>
                                    <input 
                                        type='text' 
                                        name='email'
                                        value={studentBasicObj.email}
                                        onChange={handleStudentBasicChange}
                                        onBlur={handleStudentBasicBlur}
                                        className='border border-gray-300 rounded px-2 py-1 w-80 text-blue-500'
                                    />
                                    <button className='transition delay-50 rounded-full items-center hover:bg-amber-300 p-1'>
                                        <XMarkIcon className='w-5 h-5' onClick={toggleStudentBasicEdit}/>
                                    </button>
                                    <button className='transition delay-50 rounded-full items-center hover:bg-green-500 p-1'>
                                        <CheckIcon className='w-5 h-5 ' onClick={handleStudentBasicSave}/>
                                    </button>
                                </div>
                            </div>
                            :   // if not editing
                            <div className='space-y-3'>
                                <div className='flex space-x-5'>
                                    <p className='text-sm'>Email:</p>
                                    <p className='font-semibold text-blue-500 text-sm'>{studentInfo?.email}</p>
                                </div>
                                <div className='flex space-x-5 items-center'>
                                    <p className='text-sm'>Gender: </p>
                                    <p className='font-semibold text-blue-500 text-sm'>{studentInfo?.gender}</p>
                                </div>
                            </div>
                        }
                        </div>
                    </div>
                        {/* ------------school related ----------- */}
                    <div className='flex space-x-5 items-center px-3 py-1 '>
                        <p className='text-sm'>Grade Level:</p>
                        {   
                            studentInfo?.gradeLevel && 
                            <StudentsGradeOptions studentInformation={studentInfo} refreshStudentInfo={getStudentInfo} />
                        }
                    </div>
                    <div className='flex space-x-5 items-center px-3 py-2 '>
                        <p className='text-sm'>Member Since:</p>
                        <p className='font-semibold text-blue-500 text-sm'>
                        {studentInfo?.createdAt && moment(studentInfo?.createdAt).format("MMM Do, YYYY")}
                        </p>
                    </div>
                </div>
                <SeparatorLine margin={1} />
                <div id='column-two' className='col-span-2'>
                    <div className='flex space-x-5 items-center px-3 py-2 '>
                        <p className='text-sm'>Date of Birth:</p>
                        {
                            studentInfoObjEdit?
                            <div>
                                <StudentBDayPicker handleSelect={handleStudentBDayChange} originalDate={studentInfoObj.dateOfBirth} />
                            </div>
                            :   // if not editing
                            <p className='font-semibold text-blue-500 text-sm'>
                            {studentInfoObj?.dateOfBirth && moment(studentInfoObj.dateOfBirth).format("MMM Do, YYYY")}
                            </p>
                        }
                    </div>

                    <div className='flex space-x-5 items-center px-3 py-2 '>
                        <p className='text-sm'>Nationality:</p>
                        {
                            studentInfoObjEdit?
                                <div className='w-60'>
                                    <StudentCountryPicker handleSelect={handleCountryChange}
                                    originalCountry={studentInfoObj.nationality} />
                                </div>
                                :   // if not editing
                                <p className='font-semibold text-blue-500 text-sm'>
                                    {studentInfoObj.nationality}
                                </p>
                        }
                    </div>
                    <div className='flex space-x-5 items-center px-3 m7 py-2 justify-end mt-3'>
                        {
                        studentInfoObjEdit?
                            <div className='flex space-x-5 font-semibold text-sm '>
                                <button 
                                    className='bg-slate-300 rounded lg:w-40 md:w-40 w-full py-1 px-2'
                                    onClick={toggleStudentInfoEdit}>Cancel
                                </button>
                                <button 
                                    className='bg-blue-500 rounded lg:w-40 md:w-40 w-full py-1 text-white px-2'
                                    onClick={handleStudentInfoSave}>Save
                                </button>
                            </div>
                            :   // if not editing
                            <button 
                                className='transition delay-100 bg-indigo-500 rounded lg:w-40 md:w-40 w-full py-1 text-white text-sm flex justify-center items-center space-x-2 hover:bg-indigo-600'
                                onClick={toggleStudentInfoEdit}>
                                <PencilSquareIcon className='w-5 h-5'/>
                                <p>Edit</p>
                            </button>
                        }
                    </div>
                </div>
                
                <SeparatorLine margin={1} />

                <div id='parents-info' className=''>
                <div className='lg:grid grid-cols-2 space-x-1'>
                    <div id='father' className='space-y-3'>
                        <div className='items-center px-3 py-1 font-semibold '>
                        {
                            parentInfoObjEdit?
                            <div className='editFather'>
                                <div >
                                    <p className='text-indigo-500 mb-2'>Father</p>
                                    <p className='text-xs'>First Name:</p>
                                    <input 
                                        type='text' 
                                        name='fatherFirstName'
                                        value={parentInfoObj.fatherFirstName}
                                        onChange={handleParentInfoChange}
                                        onBlur={handleParentInfoBlur}
                                        className='border border-gray-300 w-full rounded px-2 py-1 text-blue-500'
                                    />
                                </div>
                                <div>
                                    <p className='text-xs'>Last Name:</p>
                                    <input 
                                        type='text' 
                                        name='fatherLastName'
                                        value={parentInfoObj.fatherLastName}
                                        onChange={handleParentInfoChange}
                                        onBlur={handleParentInfoBlur}
                                        className='border border-gray-300 w-full rounded px-2 py-1 text-blue-500'
                                    />
                                </div>
                            </div>
                            :      // 
                            <div id='fatherName' className='flex space-x-5 text-sm'>
                                <p className='py-1'>
                                    Father:
                                </p>
                                <p className='text-blue-500 py-1'>
                                    {parentInfoObj?.fatherFirstName} {parentInfoObj?.fatherLastName} 
                                </p>
                            </div>
                        }
                        </div>
                        <div id='fatherEmail' className='items-center px-3 py-1 font-semibold '>
                            {
                                parentInfoObjEdit?
                                <div>
                                    <p className='text-xs'>Email:</p>
                                    <input
                                        type='text' 
                                        name='fatherEmail'
                                        value={parentInfoObj?.fatherEmail}
                                        onChange={handleParentInfoChange}
                                        onBlur={handleParentInfoBlur}
                                        className='border border-gray-300 w-full rounded px-2 py-1 text-blue-500'
                                    />
                                </div>
                                :    // if not editing
                                <div className='flex space-x-5 items-center text-sm'>
                                    <p className='py-1'>Email:</p>
                                    <p className='font-semibold text-blue-500 py-1'>
                                        {parentInfoObj?.fatherEmail}
                                    </p>
                                </div>
                            }
                        </div>
                        {
                            parentInfoObjEdit?
                            <div></div>
                            :       // if not editing
                            <div className='rounded space-y-3'>
                                <div className='flex space-x-5 items-center px-3 py-1 font-semibold text-sm bg-slate-100 ' >
                                    <p>Activated</p>
                                    {
                                        parentInfoObj.fatherActivated?
                                        <div className='flex space-x-5 items-center w-full justify-between'>
                                            <p className='text-green-500 items-center py-1'>Yes</p>
                                            <button className='transition delay-100 bg-red-500 text-white px-3 py-0.5 rounded-full hover:bg-red-600'>Deactivate</button>
                                        </div>
                                        :
                                        <div className='flex space-x-5  w-full justify-between'>
                                            <p className='text-red-500 py-1'>No</p>
                                            <button className='transition delay-100 border-2 border-blue-500 text-blue-500 px-3 py-0.5 rounded-full hover:bg-blue-600 hover:text-white'>Activate</button>
                                        </div>
                                    }
                                </div>
                                <div className='flex space-x-5 items-center px-3 py-1 font-semibold text-sm bg-slate-100'>
                                    <p>Enabled</p>
                                    {
                                        parentInfoObj.fatherAccountEnabled?
                                        <div className='flex space-x-5 items-center w-full justify-between'>
                                            <p className='text-green-500 items-center py-1'>Yes</p>
                                            <button 
                                                onClick={()=>handleParentDisable(parentInfoObj,parentInfoObj.fatherId)}
                                                className='transition delay-100 border-2 border-slate-300 text-slate-500 px-3 py-0.5 rounded-full hover:bg-red-600 hover:text-white'>Disable</button>
                                        </div>
                                        :
                                        <div className='flex space-x-5 w-full justify-between'>
                                            <p className='text-red-500 py-1'>No</p>
                                            <button 
                                                onClick={()=>handleParentEnable(parentInfoObj,parentInfoObj.fatherId)}
                                                className='transition delay-100 border-2 border-green-500 text-green-500 px-3 py-0.5 rounded-full hover:bg-green-600 hover:text-white'>Enable</button>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </div>

                    <div id='mother' className='space-y-3'>
                        <div className='items-center px-3 py-1 font-semibold '>
                            {
                                parentInfoObjEdit?
                                <div className='editMother'>
                                    <div>
                                        <p className='text-indigo-500 mb-2'>Mother</p>
                                        <p className='text-xs'>First Name:</p>
                                        <input 
                                            type='text' 
                                            name='motherFirstName'
                                            value={parentInfoObj?.motherFirstName}
                                            onChange={handleParentInfoChange}
                                            onBlur={handleParentInfoBlur}
                                            className='border border-gray-300 w-full rounded px-2 py-1 text-blue-500'
                                        />
                                    </div>
                                    <div>
                                        <p className='text-xs'>Last Name:</p>
                                        <input 
                                            type='text' 
                                            name='motherLastName'
                                            value={parentInfoObj?.motherLastName}
                                            onChange={handleParentInfoChange}
                                            onBlur={handleParentInfoBlur}
                                            className='border border-gray-300 w-full rounded px-2 py-1 text-blue-500'
                                        />
                                    </div>
                                </div>
                                :     // if not editing
                                <div className='flex space-x-5 text-sm'>
                                    <p className='py-1'>
                                        Mother:
                                    </p>
                                    <p className='text-blue-500 py-1'>
                                        {parentInfoObj?.motherFirstName} {parentInfoObj?.motherLastName}
                                    </p>
                                </div>
                            }
                        </div>
                        <div className=' items-center px-3 py-1 font-semibold'>
                            {
                                parentInfoObjEdit?
                                <div>
                                    <p className='text-xs'>Email:</p>
                                    <input 
                                    type='text' 
                                    name='motherEmail'
                                    value={parentInfoObj?.motherEmail}
                                    onChange={handleParentInfoChange}
                                    onBlur={handleParentInfoBlur}
                                    className='border border-gray-300 w-full rounded px-2 py-1 text-blue-500'
                                    />
                                </div>
                                :      // if not editing
                                <div className='flex space-x-5 text-sm'>
                                    <p className='py-1'>Email:</p>
                                    <p className='font-semibold text-blue-500 py-1'>
                                    {parentInfoObj?.motherEmail}
                                    </p>
                                </div>
                            }
                        </div>
                        {
                            parentInfoObjEdit?
                            <div></div>
                            :       // if not editing
                            <div className='rounded space-y-3'>
                                <div className='flex space-x-5 items-center px-3 py-1 font-semibold text-sm bg-slate-100'>
                                <p>Activated</p>
                                {
                                    parentInfoObj.motherActivated?
                                    <div className='flex space-x-5 items-center w-full justify-between'>
                                        <p className='text-green-500 items-center py-1'>Yes</p>
                                        <button className='transition delay-100 border-2 border-red-500 text-red-500 px-3 py-0.5 rounded-full hover:bg-red-600 hover:text-white'>Deactivate</button>
                                    </div>  
                                    :       // if not activated
                                    <div className='flex space-x-5 w-full justify-between'>
                                        <p className='text-red-500 py-1'>No</p>
                                        <button className='transition delay-100 border-2 border-blue-500 text-blue-500 px-3 py-0.5 rounded-full hover:bg-blue-600 hover:text-white'>Activate</button>
                                    </div>
                                }
                                </div>
                                <div className='flex space-x-5 items-center px-3 py-1 font-semibold text-sm bg-slate-100'>
                                    <p>Enabled</p>
                                    {
                                        parentInfoObj.motherAccountEnabled?
                                        <div className='flex space-x-5 items-center w-full justify-between '>
                                            <p className='text-green-500 items-center py-1 '>Yes</p>
                                            <button
                                                onClick={()=>handleParentDisable(parentInfoObj,parentInfoObj.motherId)} 
                                                className='transition delay-100 border-2 border-slate-300 text-slate-500 px-3 py-0.5 rounded-full hover:bg-red-600 hover:text-white'>Disable</button>
                                        </div>
                                        :       // if not enabled
                                        <div className='flex space-x-5 w-full justify-between '>
                                            <p className='text-red-500 py-1'>No</p>
                                            <button 
                                                onClick={()=>handleParentEnable(parentInfoObj,parentInfoObj.motherId)}
                                                className='transition delay-100 border-2 border-green-500 text-green-500 px-3 py-0.5 rounded-full hover:bg-green-600 hover:text-white'>Enable</button>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
                </div>

                <div className='flex space-x-5 items-end justify-end px-3 py-2 text-sm mt-3'>
                {
                    parentInfoObjEdit?
                    <div className='flex space-x-5 text-sm font-semibold'>
                        <button 
                            onClick={()=>setParentInfoObjEdit(!parentInfoObjEdit)}
                            className='bg-slate-300 rounded lg:w-40 md:w-40 w-full py-1 px-2'
                        >Cancel
                        </button>
                        <button 
                            className='bg-blue-500 rounded lg:w-40 md:w-40 w-full py-1 text-white px-2'
                            onClick={handleSavingParentsInfo}
                        >Save</button>
                    </div>
                    :
                    <button 
                        className='transition delay-100 bg-indigo-500 rounded lg:w-40 md:w-40 w-full py-1 text-white flex justify-center items-center space-x-2 hover:bg-indigo-600'
                        onClick={handleCancelParentInfoEdit} >
                        <PencilSquareIcon className='w-5 h-5'/>
                        <p>Edit</p>
                    </button>
                }
                </div>
            </div>
        </div>
    </div>
    </StaffLayout>
  )
}

const calculateClassYear = (currentGrade)=>{
    let graduationGrade = 12;
    let currentYear = moment().year();
    let classYear = currentYear + (graduationGrade - currentGrade);
    return classYear;
}


const customConfirm = async (message) => {
    return new Promise((resolve) => {
        const confirmed = window.confirm(message); // Replace this with a custom modal if preferred
        resolve(confirmed);
    });
};