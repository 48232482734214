import React, { useEffect, useState, useContext, useCallback } from 'react'
import { Link,useNavigate } from 'react-router-dom';
import axiosWithInterceptor from '../../../../hooks/axiosInterceptor';
import SuperAdminLayout from '../components/SuperAdminLayout';

import { AuthenticationContext } from '../../../../services/AuthContextModule';
import SuperAdmin_CreateNewUniversity from '../../adminComponents/SuperAdmin_CreateNewUniversity';

import Select from 'react-select';
import debounce from 'lodash.debounce';

export default function SuperAdminAllUniversities() {
  
    const useAxiosWithInterceptor = axiosWithInterceptor();
    const {authState} = useContext(AuthenticationContext);
    const userId = authState.user;
    const [userInfo,setUserInfo] = useState();
    const navigate = useNavigate();

    const [universities, setUniversities] = useState([]);
    const [universitiesTable, setUniversitiesTable] = useState([]);
    const [universityCount, setUniversityCount] = useState(0);

    const [searchOptions, setSearchOptions] = useState([]);

    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(10);

    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    const [searchValue, setSearchValue] = useState("");

    const handleSearch = (e)=>{
        setSearchValue(e.target.value);

        let filteredList = universities.filter((university)=>{
            const searchKey = ["schoolName","city","state","country"];
            return searchKey.some((key)=>{
                if(typeof university[key] === "string" && university[key].toLowerCase().includes(e.target.value.toLowerCase())){
                    return university
                }
            });
        });
        setUniversitiesTable(filteredList)
    }


    const getUserInfo = async ()=>{
        await useAxiosWithInterceptor.get("/api/user/profile",{withCredentials:true})
            .then((response)=>{
                if(response.status === 200){
                    setUserInfo(response.data.payload)
                }
            })
            .catch((error)=>{
                console.log(error)
        })
    }

    const getUniversities = async(offset = 0, append = false)=>{
        setLoading(true);
        await useAxiosWithInterceptor.get(`/api/super-admin/universities?offset=${offset}&limit=${limit}`,{withCredentials:true})
        .then((response)=>{
            if(response.status === 200){
                const newUniversities = response.data.payload.rows;
                setUniversityCount(response.data.payload.count)
                const updatedUniversities = append ? [...universities, ...newUniversities] : newUniversities;
                setUniversities(updatedUniversities);
                setUniversitiesTable(updatedUniversities);
                setHasMore(newUniversities.length === limit);
            }
        })
        .catch((error)=>{
            console.log(error)
        })
        .finally(() => {
            setLoading(false);
        });
    }


    const searchUniversities = async (searchQuery) => {
        setLoading(true);
        await useAxiosWithInterceptor.get(`/api/super-admin/universities/search?search=${searchQuery}`, { withCredentials: true })
        .then((response) => {
            if (response.status === 200) {
                const newUniversities = response.data.payload || [];
                // setUniversities(newUniversities);
                setSearchOptions(newUniversities.map(u => ({
                    value: u.schoolName,
                    label: `${u.schoolName}, ${u.city}, ${u.state}, ${u.country}`,
                    id: u.id
                })));
            }
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    const debouncedSearch = useCallback(debounce(searchUniversities, 500), []);

    const loadMore = () => {
        const newOffset = offset + limit;
        setOffset(newOffset);
        getUniversities(newOffset, true);
    };

    const handleInputChange = (inputValue) => {
        setSearchValue(inputValue);
        debouncedSearch(inputValue);
    };

    const handleSearchChange = (selectedOption) => {
        const searchQuery = selectedOption ? selectedOption.value : '';
        setSearchValue(searchQuery);
        setOffset(0);
        searchUniversities(searchQuery);
        // getUniversities(0, searchQuery);
        if (selectedOption) {
            navigate(`/admin/universities/${selectedOption.id}`);
        }
    };

    useEffect(()=>{
        document.title = "Universities"
        // getUserInfo();
        getUniversities();
    },[])

  return (
    <SuperAdminLayout>
    <div className='w-full h-full'>
    <div className='bg-opacity-75 px-1 py-2 lg:mx-2'>
        <p className='text-3xl font-bold py-1 px-2'> Universities ({universityCount})</p>
        <div className='px-0 sm:px-6 lg:px-8 sm:flex sm:items-center my-1 gap-x-2'>
            <div className='flex items-center w-full py-2 '>
              <p className='py-2 px-3 font-semibold border-1 text-sm bg-amber-400 rounded-l-2xl shadow-sm text-gray-600'>Search University</p>
              <Select
                value={{ value: searchValue, label: searchValue }}
                onInputChange={handleInputChange}
                onChange={handleSearchChange}
                options={searchOptions}
                placeholder='School Name, City or Country'
                className='shadow-sm block flex-1 border-0 bg-slate-100 text-gray-900 font-semibold placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 rounded-lg '
                isClearable
                />
            </div>
            <div className="sm:mt-0 sm:flex-none mx-2">
                <SuperAdmin_CreateNewUniversity refreshData={getUniversities} />
            </div>
          </div>
        <div className='rounded-2xl shadow-sm '>
          <table className="min-w-full divide-y divide-gray-300 ">
            <thead className='text-white'>
            <tr className='font-semibold '>
                <th scope="col" className=" bg-green-500 rounded-tl-2xl px-3 py-2.5 pl-4 pr-3 text-left text-sm ">Name</th>
                <th scope="col" className="bg-green-500 hidden px-3 py-2.5 text-left text-sm lg:table-cell" > City</th>
                <th scope="col" className="bg-green-500 hidden px-3 py-2.5 text-left text-sm lg:table-cell" > State</th>
                <th scope="col" className="bg-green-500 hidden px-3 py-2.5 text-left text-sm lg:table-cell" >Country</th>
                <th scope="col" className="bg-green-500 hidden px-3 py-2.5 text-left text-sm lg:table-cell" >Website </th>
                <th scope="col" className="bg-green-500 rounded-tr-2xl px-3 py-2.5 text-left text-sm font-semibold">Data % </th>
            </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white ">
              {universitiesTable.map((university,index)=>{
                return (
                    <tr key={university.id} className={`hover:bg-gray-50 `}>
                        <td className={`text-sm pl-4 py-2 font-semibold flex items-center ${index === universitiesTable.length -1?'rounded-bl-2xl ':''}`}>
                        {
                            university?.profilePicture? 
                            <img src={university?.profilePicture} className="h-10 w-10 rounded-lg mr-2" />
                            :
                            <div className='h-10 w-10'></div>
                        }
                        <Link to={`/admin/universities/${university.id}`} >
                        <p className='text-blue-600'>
                            {university?.schoolName}
                        </p>
                        </Link>
                            <dl className="font-normal lg:hidden text-sm">
                                <dt>{university?.stateProvince}</dt>
                                <dt>{university?.country}</dt>
                            </dl>
                        </td>
                        <td className='hidden px-3 py-2 text-gray-500 lg:table-cell font-semibold text-sm'>{university?.city}</td>
                        <td className='hidden px-3 py-2 text-gray-500 lg:table-cell font-semibold text-sm'>{university?.state}</td>
                        <td className='hidden px-3 py-2 text-gray-500 lg:table-cell font-semibold text-sm '>{university?.country}</td>
                        <td className={`px-3 text-blue-400 font-semibold text-sm ${index === universitiesTable.length -1?'rounded-br-2xl ':''}`}>
                            <a href={university?.website} target="_blank" >{university?.website}</a>
                        </td>
                        <td className='hidden px-3 py-2 text-gray-500 lg:table-cell font-semibold text-sm '>20%</td>
                    </tr>
                        )
                    })
                }
            </tbody>
          </table>
          {hasMore && !loading && (
            <button onClick={loadMore} className="w-full text-blue-500 font-semibold flex justify-center py-2 hover:bg-slate-300 bg-slate-200 ">
              Show more
            </button>
            )}
            {loading && (
                <div className="flex justify-center py-4">
                    <p>Loading...</p>
                </div>
            )}
        </div>
    </div>
    </div>
    </SuperAdminLayout>
 
  )
}