import React, { useContext, useEffect, useState } from 'react'
import ParentLayout from '../../components/Layouts/ParentLayout';
import axiosWithInterceptor from '../../../hooks/axiosInterceptor';
import { Link } from 'react-router-dom';
import { AuthenticationContext } from '../../../services/AuthContextModule';

export default function ParentSchoolEvents() {
  const {authState} = useContext(AuthenticationContext);
  const useAxiosWithInterceptor = axiosWithInterceptor();

  const userId = authState.user;

  return (
    <>
    <ParentLayout>
    <div className='md:w-full w-full'>
      <div className='bg-opacity-75 rounded-2xl  px-1 py-2 lg:mx-2'>
      <p>Events</p>
      </div>
    </div>
    </ParentLayout>
    </>
  )
}
