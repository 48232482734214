import React, { useContext, useEffect, useState } from 'react';
import axiosWithInterceptor from '../../../hooks/axiosInterceptor';
import { AuthenticationContext } from '../../../services/AuthContextModule';
import StudentLayout from '../../components/Layouts/StudentLayout';
import Calendar from '../../components/Calendar';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid';
import CalendarModal from '../../components/CalendarModal';
import { Link } from 'react-router-dom';

export default function StudentCalendar() {

    const useAxiosWithInterceptor = axiosWithInterceptor();
    const {authState} = useContext(AuthenticationContext);
    const userId = authState.user;
    const schoolId = authState.schoolId;

    const [events, setEvents] = useState([])
    const [formattedEvents, setFormattedEvents] = useState([]);

    const handleFormatEvent = (events)=>{
        let tempFormattedEvents = [];
        events.forEach((event)=>{
            
            let newFormat = {
                title: event.eventName,
                start: new Date(event.eventDateTime).toISOString(),
                url: `/student/${event.eventCategory.categoryName=== "UCC"?"ucc-events":"school-events"}/${event.id}`
            }
            tempFormattedEvents.push(newFormat);
        });
        setFormattedEvents(tempFormattedEvents);
    }

    const getAllEvents = async()=>{
        await useAxiosWithInterceptor.get(`/api/user/${userId}/school/${schoolId}/student/ucc-events`,{withCredentials:true})
        .then((response)=>{
            if(response.status === 200){
                setEvents(response.data.payload);
                handleFormatEvent(response.data.payload)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    useEffect(()=>{
        document.title = "Calendar";
        getAllEvents();
    },[]);

  return (
    <StudentLayout>
        <div className='md:w-full w-full'>
        <div className='bg-opacity-75 px-1 py-2 lg:mx-2 '>
            {/* <Calendar allEvents={events} /> */}
            <FullCalendar 
                plugins={[dayGridPlugin]}
                initialView='dayGridMonth'
                events={formattedEvents}
                eventContent={renderEventContent}
                eventBackgroundColor='blue-500'
            />
        </div>
        </div>
    </StudentLayout> 
  )
}

function renderEventContent(eventInfo) {
    return (
      <>
        <CalendarModal item={eventInfo.event} />
      </>
    )
  }