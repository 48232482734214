import React, { useState,useContext, useEffect } from 'react';
import Layout from '../../../components/Layout';
import StaffLayout from '../../../components/Layouts/StaffLayout';

import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid';
import Papa from 'papaparse';
import { useLocation,useNavigate } from 'react-router-dom';

import axiosWithInterceptor from '../../../../hooks/axiosInterceptor';
import { AuthenticationContext } from '../../../../services/AuthContextModule';


export default function AdministratorOnboardSuccess() {

    const location = useLocation();
    const newlyAddedStudents = location.state?.newlyAddedStudents;
    const [newStudents, setNewStudents] = useState(newlyAddedStudents);

    useEffect(()=>{
      setNewStudents(newlyAddedStudents);
    },[])

  return (
    <StaffLayout>
      <div className='md:w-full w-full'>
        <div className='bg-opacity-75 rounded-2xl bg-white px-1 py-2'>
          <div className='flex justify-center items-center'>
            <div className='flex flex-col items-center'>
              <UserCircleIcon className='h-20 w-20 text-green-500'/>
              <p className='text-lg font-semibold'>Students Successfully Onboarded</p>
            </div>
          </div>
          <div className='flex justify-center items-center'>
            <div className='flex flex-col items-center'>
              <p className='text-lg font-semibold'>Newly Added Students</p>
              <div className='flex flex-col items-center'>
                <p className='text-lg font-semibold'>{newStudents.length}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StaffLayout>
  )
}
