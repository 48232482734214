import React from 'react'
import SuperAdminLayout from '../components/SuperAdminLayout';

export default function SuperAdminHome() {
  return (
    <>
        <SuperAdminLayout>
          <div className='w-full px-3'>
            <div>AdminHome</div>
          </div>
        </SuperAdminLayout>
    </>
  )
}
