import React,{useEffect, useState, useContext} from 'react'
import { Link } from 'react-router-dom';
import moment from 'moment';
import SeparatorLine from '../../schoolGeneral/schoolComponents/SeparatorLine';
import { LockClosedIcon,LockOpenIcon,ClockIcon} from '@heroicons/react/20/solid';
import EventCalendarIcon from '../../schoolGeneral/schoolComponents/EventCalendarIcon';
import { AuthenticationContext } from '../../../services/AuthContextModule';
import Student_SingleEventFeedStatus from './Student_SingleEventFeedStatus';

export default function StudentSingleEventFeedCard({eventObject}) {
    
    const {authState} = useContext(AuthenticationContext);
    const userId = authState.user;

    const [hideCommentBox, setHideCommentBox] = useState(true);
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    
    const [pastEvent, setPastEvent] = useState(false);

    const [gradeLevels, setGradeLevels] = useState([]);

    const [isIncluded, setIsIncluded] = useState("");

    const handleIsIncluded = ()=>{
        const isInvited = eventObject.invitations.some((invitation)=>{
            return invitation.student.id === userId
            }
        )
        if(isInvited){
            //check if accepted invitation
            eventObject.invitations.filter((invitation)=>{
                if(invitation.student.id === userId){
                    if(invitation.status === "accepted"){
                        setIsIncluded("Accepted Invitation")
                    }else if (invitation.status === "declined"){
                        setIsIncluded("Declined Invitation")
                    } else if (invitation.status === "pending"){
                        setIsIncluded("Pending Invitation")
                    }
                }
            })
        }else{
            setIsIncluded("Not On List")
        }
    }

    const getGradeLevels = ()=>{
        let students = eventObject.invitations.map((invitation)=>{
            return invitation.student
            }
        )
        let gradeLevels = students.map((student)=>{
            return student.gradeLevel
        })
        gradeLevels = gradeLevels.sort((a,b)=>a-b);
        gradeLevels = [...new Set(gradeLevels)];
        setGradeLevels(gradeLevels);
    }

    useEffect(()=>{
        if(moment(eventObject.eventDateTime).isBefore(moment())){
            setPastEvent(true)
        }
    });

    useEffect(()=>{
        getGradeLevels();
    },[eventObject]);

    useEffect(()=>{
        handleIsIncluded();
    },[eventObject]);

  const momentOptions = {
    sameDay: '[Today] (ddd) [at] LT', // Today (Mon) at 2:30 PM
    nextDay: '[Tomorrow] (ddd) [at] LT', // Tomorrow (Tues) at 2:30 PM
    nextWeek: 'ddd [at] LT', // Sun at 2:30 PM
    lastDay: '[Yesterday] (ddd) [at] LT', // Yesterday (Sun) at 2:30 PM
    lastWeek: '[Last] ddd [at] LT', // Last Mon at 2:30 PM
    sameElse: 'ddd [at] LT' // Fri at 2:30 PM
};

  return (
    <>
    <div id='event-card' className='text-sm bg-white shadow-sm border-slate-200 rounded-xl'>
        <Link to={`/student/school-events/${eventObject.id}`} >
            <div id='event-title' className='transition delay-50 flex items-center col-span-5 justify-between hover:bg-slate-700 shadow-sm hover:text-white text-gray-800 px-3 py-2 mb-2 border-b-2 border-slate-500 group rounded-t-xl'>
                <div className='flex w-full'>
                    <p className='text-lg font-semibold  hover:cursor-pointer'>{eventObject.eventName}</p>
                </div>
                <p className='min-w-fit px-3 py-0.5 font-semibold rounded-2xl border-2 border-slate-200 text-blue-500 group-hover:text-white shadow-sm'>{eventObject.eventCategory?.categoryName.toUpperCase()}</p>
            </div>
        </Link>
        <div className='px-3 py-1'>
            <div className='flex w-full justify-between rounded space-x-4'>
                <EventCalendarIcon date={eventObject.eventDateTime} />
                <div className='rounded border-2 flex flex-col h-full '>
                    <div className='flex py-2 justify-center'>
                        <ClockIcon className='h-5 w-5 text-blue-500 mr-2 text'/>
                        <p className='text-sm text-center font-semibold text-blue-700 '>{moment(eventObject.eventDateTime).calendar(null,momentOptions)} </p>
                    </div>
                    
                {
                    moment().isBefore(moment(eventObject.signUpDeadline))?
                    eventObject.openToAll?
                    ( <Link to={`/student/school-events/${eventObject.id}`} >
                        <div className="px-5 py-1 text-sm bg-green-500 text-center rounded-b flex justify-center">
                            <LockOpenIcon className='h-5 w-5 text-white mx-2' /> 
                            <p className='font-semibold text-white'>Open to Join</p>
                        </div>
                    </Link> )
                    : // if event is not open to all
                    (<div className="px-5 py-1 text-sm bg-gray-100 text-center rounded-b flex justify-center">
                        <LockClosedIcon className='h-6 w-6 text-red-500 mx-2' /> 
                        <p className='font-semibold'>Invite Only</p>
                    </div>)
                    :   // if event is past
                    (<div className="px-5 py-1 text-sm bg-amber-500 text-center rounded-b ">
                        <p className='font-semibold text-white'>Sign up Closed</p>
                    </div>)
                }
                </div>
            </div>
            <div className='px-0 py-1 flex justify-between items-center' >
                <div className='flex space-x-2 items-center'>
                    {
                        gradeLevels.map((grade,index)=>{
                            return <p key={index} className='font-semibold text-base text-center flex items-center justify-center w-9 h-9 rounded-full shadow-sm border-3 border-teal-500'>{grade}</p>
                        })
                    }
                </div>
                <div className='flex space-x-2 items-center text-xs'>
                    <Student_SingleEventFeedStatus eventObject={eventObject} userId={userId} />
                </div>
                <div className='py-0'>
                    <p className='font-semibold text-xs '>Created by: {eventObject.staff.firstName} {eventObject.staff.lastName} {eventObject.staff.profilePicture}</p>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}