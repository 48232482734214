import React from 'react'
import Layout from '../../components/Layout'
import StaffLayout from '../../components/Layouts/StaffLayout';

export default function SchoolAllContactUs() {
  return (
    <StaffLayout>
    <div className='md:w-full w-full'>
        <div className='bg-opacity-75 px-1 py-2 lg:mx-2'>
            <div className="mx-auto max-w-2xl space-y-16 divide-y divide-gray-100 lg:mx-0 lg:max-w-none">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-3">
                    <div>
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900">Get in touch</h2>
                    <p className="mt-4 leading-7 text-gray-600">
                        If you need help with any of our services, please don't hesitate to contact our support team.
                    </p>
                    </div>
                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:col-span-2 lg:gap-8">
                    <div className="rounded-2xl bg-gray-50 p-10">
                        <h3 className="text-base font-semibold leading-7 text-gray-900">Support</h3>
                        <dl className="mt-3 space-y-1 text-sm leading-6 text-gray-600">
                        <div>
                            <dt className="sr-only">Email</dt>
                            <dd>
                            <a className="font-semibold text-indigo-600" href="mailto:collaborate@example.com">
                                support@schoolley.com
                            </a>
                            </dd>
                        </div>
                        </dl>
                    </div>
  
                    <div className="rounded-2xl bg-gray-50 p-10">
                        <h3 className="text-base font-semibold leading-7 text-gray-900">Join our team</h3>
                        <dl className="mt-3 space-y-1 text-sm leading-6 text-gray-600">
                        <div>
                            <dt className="sr-only">Email</dt>
                            <dd>
                            <a className="font-semibold text-indigo-600" href="mailto:careers@example.com">
                                contact@schoolley.com
                            </a>
                            </dd>
                        </div>
                        </dl>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </StaffLayout>
  )
}
