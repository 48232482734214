import React, { useContext,useEffect } from 'react'
import UnAuthLayout from './components/UnAuthLayout';
import axios from '../api/axios';
import { useState} from 'react';
import {Link, useNavigate } from 'react-router-dom';
import { AuthenticationContext } from '../services/AuthContextModule';
import { useBanner } from '../services/SystemBannerMessage';
import useRefreshToken from '../hooks/useRefreshToken';
import GoogleLogin from './GoogleLogin';

export default function Login() {

    const {authState,setAuthState} = useContext(AuthenticationContext);
    const doRefreshToken = useRefreshToken();

    const navigateTo = useNavigate();
    const [email,setEmail] = useState('');
    const [password,setPassword] = useState('')
    const [validated,setValidated] = useState(false);
    const {showBanner} = useBanner();

    const handleEmailChange = (e)=>{
        setEmail(e.target.value);
    }
    const handlePasswordChange = (e)=>{
        setPassword(e.target.value);
    }

    const handleSubmit = (e)=>{
        e.preventDefault();
        
        if(e.currentTarget.checkValidity() === false){
            e.stopPropagation();
            setValidated(true)
        } else{
            const form = new FormData();
            form.append('email',email);
            form.append('password',password)
            
            axios.post("/api/auth/login",form,{
                withCredentials:true
            }).then((response)=>{
              if(response.status === 200){
                  let updatedAuthState = {
                    accessToken: response.data.accessToken,
                      user:response.data.userId,
                      role:response.data.role,
                      isAuthenticated:true,
                      firstName:response.data.firstName,
                      profilePicture:response.data.profilePicture,
                      isActivated:response.data.activated,
                      isArchived:response.data.archived,
                      schoolId:response.data.schoolId,
                      subscriptionStatus:response.data.subscriptionStatus
                  }
                                  
                if (response.data.studentId) {
                  updatedAuthState.studentIds = response.data.studentIds;
                }
                setAuthState(updatedAuthState);
                navigateTo('/')
              }
            }).catch((error)=>{
              if (error.response && error.response.status === 401) {
                showBanner("Invalid email or password", "bg-yellow-400");
              } else {
                console.error("Login error:", error);
                showBanner("An error occurred. Please try again.", "bg-yellow-500");
              }
            })
        }
    }
  
    useEffect(() => {
      let isMounted = true;
    
      const verifyRefreshToken = async () => {
        try {
          await doRefreshToken();
        } catch (error) {
          // console.log("Persistent Login Error", error);
        } finally {
          if (isMounted) {
            // console.log("refresh token ran");
          }
        }
      };
    
      // Only refresh if we *do* have a token and want to keep it fresh
      if (authState?.accessToken) {
        verifyRefreshToken();
      }
    
      return () => {
        isMounted = false;
      };
    }, [authState?.accessToken]);
  
  useEffect(() => {
    if (authState?.accessToken) {
      navigateTo('/'); // Redirect to the home page if logged in
    }
  }, [authState, navigateTo]);

  return (
    <UnAuthLayout>
    <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Welcome
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                  Email address
                </label>
                <div id='email-address' className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={handleEmailChange}
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                  Password
                </label>
                <div id='passwords' className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    placeholder='Password' 
                    autoComplete="current-password"
                    required
                    className="block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={handlePasswordChange}
                    value={password}
                  />
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                  <label htmlFor="remember-me" className="ml-3 block text-sm leading-6 text-gray-900">
                    Remember me
                  </label>
                </div>

                <div className="text-sm leading-6">
                  <Link to={"/forgotpassword"} className="font-semibold text-indigo-600 hover:text-indigo-500">
                    Forgot password?
                  </Link>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-gray-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" >
                  Log in
                </button>
              </div>
            </form>
            {/* Google Login */}
            {/* <div>
              <div className="relative my-2">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="w-full border-t border-gray-200" />
                </div>
                <div className="relative flex justify-center text-sm font-medium leading-6">
                  <span className="bg-white px-6 text-gray-900">Or continue with</span>
                </div>
              </div>
              <GoogleLogin />
            </div> */}
            {/* Google Login */}

          </div>
        </div>
    </div>
    </UnAuthLayout>
  )
}
