import React, { useContext, useEffect, useState } from 'react'
import Layout from '../../../../components/Layout';
import StaffLayout from '../../../../components/Layouts/StaffLayout';

import SeparatorLine from '../../../schoolComponents/SeparatorLine';
import axiosWithInterceptor from '../../../../../hooks/axiosInterceptor';
import { AuthenticationContext } from '../../../../../services/AuthContextModule';
import moment from 'moment';
import { Link, useLocation,useNavigate,useParams } from "react-router-dom";

export default function Subscription() {
  const {authState} = useContext(AuthenticationContext);
  const useAxiosWithInterceptor = axiosWithInterceptor();
  const userId = authState.user;
  const schoolId = authState.schoolId;

  const [expirationWarning, setExpirationWarning] = useState("");
 
  const [activeSubscription, setActiveSubscription] = useState([]);
  const [previousSubs, setPreviousSubs] = useState([]);

  const checkExpiration = (activeSubs)=>{
    let today = moment().startOf('day');
    let aWeekFromNow = moment().add(7, 'days').endOf('day');
    let aMonthFromNow = moment().add(1, 'month').endOf('day');
    let twoMonthFromNow = moment().add(2,'month').endOf('day');

    // clean and sort subscriptions in order of time;
    let currentSub = activeSubs.find((sub)=>{
      return moment(sub.startingDate).isBefore(moment()) && moment(sub.endingDate).isAfter(moment());
    });
    
    let futureSubs;

    // if expiration ate is within 2 months 
      if(moment(currentSub.endingDate).isBetween(today,twoMonthFromNow,'day','[]')){
        
      }
      // if within 2 months and have another 1 to overlaps
      // 
      // else
        // your subscription is expiring in 2 months on date: xxxx

    // if expiration date is within a month,
      // if within 1 months and have another 1 to overlaps
      // else
        // your subscription is expiring in 1 months on date: xxxx

    // if expiration date is within a week
      // if within 1 week and have another 1 to overlaps
      // else
        // your subscription is expiring in 1 week on date: xxxx

    // is expired and has no overlaps
  }

  const handleActiveSubscription = (subscriptions)=>{
    let active = subscriptions.filter((sub)=>{
      return sub.status === "active"
    });
    setActiveSubscription(active)
  }

  const handlePreviousSubs = (subscriptions)=>{
    let previous = subscriptions.filter((sub)=>{
      return sub.status === "expired" || sub.status === "inactive"
    });
    setPreviousSubs(previous)
  }

  const getSubscriptionInfo = async()=>{
    await useAxiosWithInterceptor.get(`api/user/${userId}/school/${schoolId}/administrator/subscriptions`,{withCredentials:true})
    .then((response)=>{
      if(response.status === 200){
        handleActiveSubscription(response.data.payload);
        handlePreviousSubs(response.data.payload);
      }
    })
    .catch((error)=>{
      console.log(error)
    })
  }

  useEffect(()=>{
    getSubscriptionInfo();
  },[])
  return (
    <StaffLayout>
      <div className='md:w-full w-full'>
        <div className='bg-opacity-75 px-1 py-2 lg:mx-2'>
          <p className='text-2xl mb-3 px-1 font-semibold'>ACTIVE SUBSCRIPTION</p>
          <SeparatorLine margin={3} />
          <div className=''>
            <div className='shadow-md ring-1 ring-gray-900/5 rounded-lg bg-white'>
                <table className="w-full whitespace-nowrap text-left text-sm leading-6 ">
                  <thead className="border-b border-gray-200 text-gray-900 bg-green-500 rounded-t-lg">
                    <tr className='text-center '>
                      <th className="py-2 font-semibold">Type </th>
                      <th className="py-2 font-semibold">Starting Date</th>
                      <th className=" py-2 font-semibold sm:table-cell">Ending Date</th>
                      <th className=" py-2 font-semibold sm:table-cell">Status</th>
                      <th className=" py-2 font-semibold sm:table-cell">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      activeSubscription.map((sub)=>(
                        <tr key={sub.id} className='text-blue-500'>
                          <td className="pl-3 py-2 align-top font-bold  text-center">
                            {sub?.subscriptionType}
                          </td>
                          <td className="pl-3 py-2 align-top font-bold  text-center">
                            {moment(sub?.startingDate).format("MM-DD-YYYY")}
                          </td>
                          <td className=" pl-3 py-2 align-top font-bold  text-center">
                          {moment(sub?.endingDate).format("MM-DD-YYYY")}
                          </td>
                          <td className=" pl-3 py-2 align-top font-bold text-green-600 text-center">
                          {sub?.status}
                          </td>
                          <td className=" pl-3 py-2 align-top font-bold text-blue-600 text-center">
                          $ {sub?.price}
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
            </div>
            <div className='px-3'>
            </div>
            <div className='my-3 '>
              <p className='mb-3 font-semibold text-red-500'>{expirationWarning}</p>
              <button className='transition delay-50 lg:w-56 w-full bg-blue-500 text-white font-semibold rounded-lg py-2 text-lg hover:bg-blue-600  '>Renew Subscription</button>
            </div>
          </div>
          <SeparatorLine margin={2} />
          <div className=''>
            <div className='my-4 flex justify-between'>
              <p className='font-semibold text-2xl'>
                Subscription History
              </p>
              <button
                  type="button"
                  className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >Print </button>
            </div>
            <div className='shadow-md ring-1 ring-gray-900/5 rounded-lg bg-white'>
              <table className="w-full whitespace-nowrap text-left text-sm leading-6 ">
                <thead className="border-b border-gray-200 text-gray-900 bg-slate-200">
                  <tr className='text-center'>
                    <th className="py-2 font-semibold">Type </th>
                    <th className="py-2 font-semibold">Starting Date</th>
                    <th className=" py-2 font-semibold sm:table-cell">Ending Date</th>
                    <th className=" py-2 font-semibold sm:table-cell">Status</th>
                    <th className=" py-2 font-semibold sm:table-cell">Price</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    previousSubs.map((sub)=>(
                      <tr key={sub.id} className='text-center'>
                        <td className="pl-3 py-2 align-top font-medium text-gray-900 ">
                          {sub?.subscriptionType}
                        </td>
                        <td className="pl-3 py-2 align-top font-medium text-gray-900 ">
                        {moment(sub?.startingDate).format("MM-DD-YYYY")}
                        </td>
                        <td className=" pl-3 py-2 align-top font-medium text-gray-900 ">
                        {moment(sub?.endingDate).format("MM-DD-YYYY")}
                        </td>
                        <td className=" pl-3 py-2 align-top font-medium text-gray-900 ">
                        {sub?.status}
                        </td>
                        <td className=" pl-3 py-2 align-top font-medium text-gray-900 ">
                        $ {sub?.price}
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
            <SeparatorLine margin={2} />
            <div className='my-4 font-semibold text-2xl py-2'>
              <p>Summary</p>

            </div>
          </div>
        </div>
      </div>
    </StaffLayout>
  )
}
