import React, { useContext, useEffect, useState } from 'react'
import axiosWithInterceptor from '../../../hooks/axiosInterceptor';
import { Link } from 'react-router-dom';
import { AuthenticationContext } from '../../../services/AuthContextModule';
import Layout from '../../components/Layout';
import ParentLayout from '../../components/Layouts/ParentLayout';

export default function ParentInvitations() {
    const {authState} = useContext(AuthenticationContext);
    const useAxiosWithInterceptor = axiosWithInterceptor();

  return (
    <>
    <ParentLayout>
    <div className='md:w-full w-full'>
      <div className='bg-opacity-75 rounded-2xl  px-1 py-2 lg:mx-2'>
        <p>Parent_Invitations</p>

      </div>
    </div>
    </ParentLayout>
    </>
  )
}
