import React, { useContext, useEffect, useState } from 'react'
import Layout from '../../../components/Layout';
import axiosWithInterceptor from '../../../../hooks/axiosInterceptor';
import { Link } from 'react-router-dom';
import SuperAdminLayout from '../components/SuperAdminLayout';

import { AuthenticationContext } from '../../../../services/AuthContextModule';
import moment from 'moment';
import SuperAdmin_AddEventTypeButton from '../../adminComponents/SuperAdmin_AddEventTypeButton';
import SuperAdmin_EditEventTypeButton from '../../adminComponents/SuperAdmin_EditEventTypeButton';
import SuperAdmin_DeleteEventTypeButton from '../../adminComponents/SuperAdmin_DeleteEventTypeButton';

export default function SuperAdminSettings() {

    const useAxiosWithInterceptor = axiosWithInterceptor();
    const {authState} = useContext(AuthenticationContext);
    const userId = authState.user;
    const schoolId = authState.schoolId;

    const [existingEventTypes, setExistingEventTypes] = useState([]);

    const getEventTypes = async()=>{
        await useAxiosWithInterceptor.get(`/api/super-admin/settings/event-types`,{withCredentials:true})
        .then((response)=>{
            if(response.status === 200){
                console.log(response.data.payload)
                setExistingEventTypes(response.data.payload);
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const handleEditEventType = async (eventTypeId,formObj)=>{
        await useAxiosWithInterceptor.put(`/api/super-admin/settings/event-types/${eventTypeId}/edit`,formObj,{withCredentials:true})
        .then((response)=>{
            if(response.status === 200){
                getEventTypes();
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const handleDeleteEventType = async (eventTypeId)=>{
        await useAxiosWithInterceptor.delete(`/api/super-admin/settings/event-types/${eventTypeId}/delete`,{withCredentials:true})
        .then((response)=>{
            if(response.status === 200){
                getEventTypes();
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    useEffect(()=>{
        getEventTypes();
    }  ,[]);

  return (
    <SuperAdminLayout>
    <div className='md:w-full w-full '>
      <div className='bg-opacity-75 rounded-2xl px-1 py-2 lg:mx-2'>
        <p className='text-3xl font-bold py-1 px-2'>School Overall Settings</p>
        <div className='bg-opacity-75 px-2 py-2 my-2'>
          <p className='text-xl font-bold py-1 px-2'>Event Types</p>
            <div className="px-2">
                <div className="sm:flex sm:items-center">
                    <div className="">
                        <p className="my-2 text-sm font-semibold text-gray-500">
                            List of types of Events, for school admins to create events and link to the type of event. for search and filter purposes.
                        </p>
                    </div>
                    <div className="my-2 sm:ml-4 sm:mt-0 sm:flex-none">
                        <SuperAdmin_AddEventTypeButton refreshData={getEventTypes} />
                    </div>
                </div>
                <div className="mt-1 flow-root">
                <div className="ring-opacity-5 overflow-x-auto ">
                    <div className="inline-block min-w-full py-2 align-middle ">
                        <table className=" divide-y divide-gray-300 border-2">
                        <thead>
                          <tr className="divide-x divide-gray-200">
                            <th scope="col" className="py-3.5 px-4 text-sm font-semibold text-gray-900 sm:pl-0">
                                Type Name
                            </th>
                            <th scope="col" className="px-4 text-left text-sm font-semibold text-gray-900">
                                Description
                            </th>

                            <th scope="col" className="px-4 text-left text-sm font-semibold text-gray-900 sm:pr-0">
                                Date Modified
                            </th>
                            <th scope='col' className='px-4 text-center text-sm font-semibold text-gray-900 sm:pr-0'>
                                Edit
                            </th>
                            <th scope='col' className='px-4 text-center text-sm font-semibold text-gray-900 sm:pr-0'>
                                Delete
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                            {existingEventTypes.map((eventType) => (
                            <tr key={eventType.id} className="divide-x divide-gray-200">
                                <td className="whitespace-nowrap py-3 px-4 text-sm font-medium text-gray-900 sm:pl-0">
                                {eventType.typeName}
                                </td>
                                <td className="whitespace-nowrap py-3 px-4 text-sm text-gray-500">{eventType.typeDescription}</td>
                                <td className="whitespace-nowrap py-3 px-4 text-sm text-gray-500 sm:pr-0">{moment(eventType.updatedAt).format('LLLL')}</td>
                                <td className='whitespace-nowrap px-4 text-sm text-gray-500 sm:pr-0 '>
                                    {/* <EditCategoryButton category={type} editCategory={handleEditCategory} /> */}
                                    <SuperAdmin_EditEventTypeButton eventType={eventType} editEventType={handleEditEventType} />
                                </td>
                                <td className='whitespace-nowrap px-4 text-sm text-gray-500 sm:pr-0 '>
                                    {/* <DeleteCategoryButton category={type} deleteCategory={handleDeleteCategory} /> */}
                                    <SuperAdmin_DeleteEventTypeButton eventType={eventType} deleteEventType={handleDeleteEventType} />
                                </td>
                            </tr>
                            ))}
                        </tbody>
                        </table>
                    </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
    </SuperAdminLayout>
  )
}
