import React, { useContext, useEffect, useState } from 'react'
import Layout from '../../../../components/Layout';
import StaffLayout from '../../../../components/Layouts/StaffLayout';

import Calendar from '../../../../components/Calendar';
import axiosWithInterceptor from '../../../../../hooks/axiosInterceptor';
import { AuthenticationContext } from '../../../../../services/AuthContextModule';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid';

export default function CounselorCalendar() {
  const useAxiosWithInterceptor = axiosWithInterceptor();
  const {authState} = useContext(AuthenticationContext);
  const userId = authState.user;
  const schoolId = authState.schoolId;

  const [visits, setVisits]= useState([]);

  const getAllVisits = async()=>{
    await useAxiosWithInterceptor.get(`/api/user/${userId}/school/${schoolId}/counselor/events`,{withCredentials:true})
    .then((response)=>{
      if(response.status === 200){
        setVisits(response.data.payload);
      }
    })
    .catch((error)=>{
      console.log(error);
    })
  }


  useEffect(()=>{
    document.title = "Calendar";
    getAllVisits();
  },[])

  return (
    <StaffLayout>
        <div className='md:w-full w-full'>
        <div className='bg-opacity-75 rounded-2xl bg-white px-1 py-2'>
            <Calendar allEvents={visits}/>
          </div>
        </div>
    </StaffLayout>
  )
}
