import React from 'react'
import { Link, NavLink } from 'react-router-dom';
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {XMarkIcon,} from '@heroicons/react/24/outline'
import {Cog6ToothIcon,CalendarIcon, BuildingLibraryIcon, BuildingOffice2Icon} from '@heroicons/react/24/solid';

export default function SuperAdminSidebar({sidebarOpen, setSidebarOpen}) {

  return (
    <>
    <Transition.Root show={sidebarOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
        <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-gray-900/80" />
        </Transition.Child>
          <div className="fixed inset-0 flex">
        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
              >
        <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
          <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
            <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
              <span className="sr-only">Close sidebar</span>
              <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </button>
          </div>
          </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-green-700 px-6 pb-4">
              <div className="flex h-16 shrink-0 items-center">
                  <Link to={'/'}>
                    <img
                          className="h-8 w-auto"
                          src="/logos/appLogo.png"
                          alt="Schoolley"
                        />
                    <p className='font-semibold text-white mx-2'>SCHOOLLEY</p>
                      
                  </Link>    
              </div>
              <nav className="flex flex-1 flex-col">
                <ul role="list" className="flex flex-1 flex-col gap-y-7">
                  <li>
                    <div className="text-xs font-semibold leading-6 text-indigo-200">Menu</div>
                      <ul role="list" className="-mx-2 mt-2 space-y-1">
                        <li>
                          <Link 
                            to="/admin/schools" 
                            className='text-white text-gray-400 hover:text-white hover:bg-green-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'>
                            <BuildingOffice2Icon className="w-6 h-6" />
                            <p>Schools</p>
                          </Link>
                          </li>
                          {/* <li>
                            <Link 
                              to='/admin/students' 
                              className='text-white text-gray-400 hover:text-white hover:bg-green-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'>
                              <UsersIcon className="w-6 h-6" />
                              <p>Students</p>
                            </Link>
                          </li> */}
                          {/* <li>
                            <Link 
                              to='/admin/administrators' 
                              className=' text-white text-gray-400 hover:text-white hover:bg-green-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'>
                              <AcademicCapIcon className="w-6 h-6" />
                              <p>School Admins</p>
                            </Link>
                          </li> */}
                          <li>
                            <Link 
                              to='/admin/universities' 
                              className=' text-white text-gray-400 hover:text-white hover:bg-green-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'>
                              <BuildingLibraryIcon className="w-6 h-6" />
                              <p>Universities</p>
                            </Link>
                          </li>
                          <li>
                            <Link 
                              to='/admin/students' 
                              className=' text-white text-gray-400 hover:text-white hover:bg-green-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold' >
                              <CalendarIcon className="w-6 h-6" />
                              <p>All Events</p>
                            </Link>
                          </li>
                          <li>
                            <NavLink 
                              to={`/admin/settings`}
                              className={({ isActive }) =>`${isActive ? 'bg-white text-gray-900' : 'text-white'} hover:bg-green-800 group flex gap-x-2 rounded-md p-2 text-sm leading-6 font-semibold`}>
                              <Cog6ToothIcon className="w-6 h-6" />
                              <p>Settings</p>
                            </NavLink>
                          </li>
                          {/* <li>
                            <Link 
                              to='/admin/users' 
                              className='text-white text-gray-400 hover:text-white hover:bg-green-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'>
                              <UserGroupIcon className="w-6 h-6" />
                              <p>All Users</p>
                            </Link>
                          </li> */}
                          {/* <li>
                            <Link>
                              <div className='text-white text-gray-400 hover:text-white hover:bg-green-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'>
                                <UserIcon className="w-6 h-6" />
                                <p>Pending</p>
                              </div>
                            </Link>
                          </li> */}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-56 lg:flex-col shadow-sm">
        
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-green-700 px-6 pb-4">
            <Link to={'/'}>
              <div className="flex h-16 shrink-0 items-center">
                <img
                  className="h-8 w-auto"
                  src="/logos/appLogo.png"
                  alt="Schoolley" />
                  <p className='font-semibold text-white mx-2'>SCHOOLLEY</p>
              </div>
            </Link>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <div className="text-xs font-semibold leading-6 text-indigo-200">Menu</div>
                  <ul role="list" className="-mx-2 mt-2 space-y-1">
                    <li>
                      <NavLink 
                        to="/admin/schools" 
                        className={({ isActive }) =>`${isActive ? 'bg-white text-gray-900' : 'text-white'} hover:bg-green-800 group flex gap-x-2 rounded-md p-2 text-sm leading-6 font-semibold`}>
                        <BuildingOffice2Icon className="w-6 h-6" />
                        <p>Schools</p>
                      </NavLink>
                    </li>
                    {/* <li>
                      <NavLink 
                        to='/admin/students' 
                        className={({ isActive }) =>`${isActive ? 'bg-white text-gray-900' : 'text-white'} hover:bg-green-800 group flex gap-x-2 rounded-md p-2 text-sm leading-6 font-semibold`}>
                        <UsersIcon className="w-6 h-6" />
                        <p>Students</p>
                      </NavLink>
                    </li> */}
                    {/* <li>
                      <NavLink 
                        to='/admin/administrators' 
                        className={({ isActive }) =>`${isActive ? 'bg-white text-gray-900' : 'text-white'} hover:bg-green-800 group flex gap-x-2 rounded-md p-2 text-sm leading-6 font-semibold`}>
                        <AcademicCapIcon className="w-6 h-6" />
                          <p>School Admins</p>
                      </NavLink>
                    </li> */}
                    <li>
                        <NavLink 
                          to='/admin/universities' 
                          className={({ isActive }) =>`${isActive ? 'bg-white text-gray-900' : 'text-white'} hover:bg-green-800 group flex gap-x-2 rounded-md p-2 text-sm leading-6 font-semibold`}>
                        
                        <BuildingLibraryIcon className="w-6 h-6" />
                        <p>Universities</p>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink 
                        to='/admin/events' 
                        className={({ isActive }) =>`${isActive ? 'bg-white text-gray-900' : 'text-white'} hover:bg-green-800 group flex gap-x-2 rounded-md p-2 text-sm leading-6 font-semibold`}>
                        <CalendarIcon className="w-6 h-6" />
                          <p>All Events</p>
                        </NavLink>
                    </li>
                    {/* <li>
                      <NavLink 
                        to='/admin/users' end={true} 
                        className={({ isActive }) =>`${isActive ? 'bg-white text-gray-900' : 'text-white'} hover:bg-green-800 group flex gap-x-2 rounded-md p-2 text-sm leading-6 font-semibold`}>
                        <UserGroupIcon className="w-6 h-6" />
                        <p>All Users</p>
                      </NavLink>
                    </li> */}
                    {/* <li>
                      <NavLink 
                        to='/admin/users/pending' 
                        className={({ isActive }) =>`${isActive ? 'bg-white text-gray-900' : 'text-white'} hover:bg-green-800 group flex gap-x-2 rounded-md p-2 text-sm leading-6 font-semibold`}>
                        <UserIcon className="w-6 h-6" />
                        <p>Pending</p>
                      </NavLink>
                    </li> */}
                    <li>
                      <NavLink 
                        to={`/admin/settings`}
                        className={({ isActive }) =>`${isActive ? 'bg-white text-gray-900' : 'text-white'} hover:bg-green-800 group flex gap-x-2 rounded-md p-2 text-sm leading-6 font-semibold`}>
                        <Cog6ToothIcon className="w-6 h-6" />
                        <p>Settings</p>
                      </NavLink>
                    </li>
                  </ul>
                </li>

              </ul>
            </nav>
          </div>
        </div>
    </>
  )
}
