import useAuthenticationContext from "./authStateHook";
import {axiosWithInterceptor} from "../api/axios";
import { useNavigate,useLocation } from 'react-router-dom';

const useRefreshToken = ()=>{
    const {authState,setAuthState} = useAuthenticationContext();
    const navigateTo = useNavigate();
    const location = useLocation();
    const refresh = async ()=>{
        await axiosWithInterceptor.get("/api/auth/refresh",{withCredentials:true})
        .then((response)=>{
            if(response.status === 200){
                const updatedState = {
                    accessToken: response.data.accessToken,
                    user:response.data.userId,
                    role:response.data.role,
                    isAuthenticated:true,
                    firstName:response.data.firstName,
                    profilePicture:response.data.profilePicture,
                    isActivated:response.data.activated,
                    schoolId:response.data.schoolId,
                    isArchived:response.data.archived,
                    subscriptionStatus:response.data.subscriptionStatus,
                }
                
                if (response.data.studentId) {
                    updatedState.studentIds = response.data.studentIds;
                }

                setAuthState(updatedState);
                return response.data.accessToken;
            } else {
                // Unexpected status code
                throw new Error(`Unexpected response status: ${response.status}`);
            }
            // return response.data.accessToken;
        }).catch((error)=>{
            // console.log("refresh error")
            navigateTo('/login',{state:{from: location},replace:true});
            throw error;
        })
    }

    return refresh;
}

export default useRefreshToken;