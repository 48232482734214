import React, { useContext, useEffect, useState } from 'react'
import Layout from '../../../../components/Layout';
import StaffLayout from '../../../../components/Layouts/StaffLayout';

import axiosWithInterceptor from '../../../../../hooks/axiosInterceptor';
import { AuthenticationContext } from '../../../../../services/AuthContextModule';

import {getDateOnly, getTimeOnly } from '../../../../../utils/cusDateFormatter';
import { Link } from 'react-router-dom';
import StudentListPopOver from '../../counselorComponents/StudentListPopOver';
import { useBanner } from '../../../../../services/SystemBannerMessage';
import moment from 'moment';
import SkeletonTableRow from '../../../../pageComponents/SkeletonTableRow';
import Linkify from 'linkify-react';

const linkifyOptions = {
  className: 'text-white font-semibold px-2 py-0.5 rounded bg-red-500 hover:bg-red-600 whitespace-nowrap', // Tailwind classes for styling the links
  target: '_blank', // Open links in a new tab
  rel: 'noopener noreferrer', // For security reasons
  truncate: 22, // Truncate long links
  format: (value, type) => {
    if (type === "url" && value.length > 10) {
      value = "External Link";
    }
    return value;
  },
};

export default function CounselorEvents() {

  const useAxiosWithInterceptor = axiosWithInterceptor();
  const {authState} = useContext(AuthenticationContext);
  const userId = authState.user;
  const schoolId = authState.schoolId;
  const {showBanner} = useBanner();

  const [isLoading, setIsLoading] = useState(true);

  const [allVisits, setAllVisits] = useState([]);
  const [thisWeekVisits, setThisWeekVisits] = useState([]);
  const [thisMonthVisit, setThisMonthVisits] = useState([]);
  const [futureVisits, setFutureVisits] = useState([]);
  const [pastVisits,setPastVisits] = useState([]);
  const [visitsTable, setVisitsTable] = useState([]);

  const [visitTabs,setVisitTabs] = useState([
      {active:true,tabName:"ALL",content:allVisits},
      {active:false,tabName:" 30 DAYS",content:thisMonthVisit},
      {active:false,tabName:"30 DAYS +",content:futureVisits},
      {active:false,tabName:"PAST",content:pastVisits},
    ])
  
  const [searchValue,setSearchValue] = useState("");

// Methods 
  const handleSetContent = (index,visitContent)=>{
    const newActiveTabs = [...visitTabs];
    newActiveTabs[index].content = visitContent;
    setVisitTabs(newActiveTabs);
  }

  const handleVisitTable = (visitData,index)=>{
    setVisitsTable(visitData);
    const newActiveTabs = [...visitTabs];
    newActiveTabs.forEach((tab)=>{
      tab.active = false;
    });
    newActiveTabs[index].active = true;
    newActiveTabs[index].content = visitData
    setVisitTabs(newActiveTabs)
  }

  const handleThisWeek = (visitsData)=>{
    
    const thisWeek = visitsData.filter((visit)=>{
      const eventDate = new Date(visit.eventDateTime);
      return (new Date(visit.eventDateTime) - new Date() < 7 *24 * 60 *60 * 1000) && (new Date(visit.eventDateTime) - new Date() > 0)
    });
    setThisWeekVisits(thisWeek);
    handleSetContent(0,thisWeek);
  }

  const handleThisMonth = (visitData)=>{
    const thisMonth = visitData.filter((visit)=>{
      const eventDate = new Date(visit.eventDateTime);
      return (eventDate - new Date() < 30 *24 * 60 *60 * 1000) && (eventDate - new Date() > 0)
    })
    setThisMonthVisits(thisMonth);
    handleSetContent(1,thisMonth);
  }
 
  const handleFuture = (visitData)=>{
    const futureVisits = visitData.filter((visit)=>{
      const eventDate = new Date(visit.eventDateTime);
      return (eventDate - new Date() > 30*24*60*60*1000)
    })
    setFutureVisits(futureVisits);
    handleSetContent(2,futureVisits);
  }
  
  const handlePast = (visitData)=>{
    const pastVisits = visitData.filter((visit)=>{
      const eventDate = new Date(visit.eventDateTime);
      return (eventDate < new Date())
    })
    handleSetContent(3,pastVisits);
  }

  const handleSearch = (e)=>{
    setSearchValue(e.target.value);

    let filteredList = allVisits.filter((visit)=>{
      const searchKey = ["eventName","location","eventDateTime"];
      return searchKey.some((key)=>{
        if(typeof visit[key] === "string" && visit[key].toLowerCase().includes(e.target.value.toLowerCase())){
          return visit
        }
      });
    });

    handleVisitTable(filteredList,0);
    const refreshedData = [...visitTabs];
    refreshedData.forEach((tab)=>{
      tab.active = false;
    });

    refreshedData[0].active = true;
    setVisitTabs(refreshedData)
  }

  // data access logics
  const getAllVisits = async ()=>{
      const nowTime = moment();
      await useAxiosWithInterceptor.get(`/api/user/${userId}/school/${schoolId}/counselor/events`,{withCredentials:true})
        .then((response)=>{
          if(response.status === 200){
            // order the data by date
            const pastData = response.data.payload.filter((visit)=>{
              return moment(visit.eventDateTime).isBefore(nowTime)
            });
            const futureData = response.data.payload.filter((visit)=>{
              return moment(visit.eventDateTime).isAfter(nowTime)
            });

            const sortedData = response.data.payload.sort((a,b)=>{
              return moment(a.eventDateTime).diff(moment(b.eventDateTime))
            });

            setAllVisits(sortedData);
            handleThisWeek(response.data.payload);
            handleFuture(response.data.payload);
            handleThisMonth(response.data.payload);
            handlePast(response.data.payload);

            // const thisWeek = response.data.payload.filter((visit)=>{
            //   const eventDate = new Date(visit.eventDateTime);
            //   return (new Date(visit.eventDateTime) - new Date() < 7 *24 * 60 *60 * 1000) && (new Date(visit.eventDateTime) - new Date() > 0)
            // });
            const sortedFutureData = futureData.sort((a,b)=>{
              return moment(a.eventDateTime).diff(moment(b.eventDateTime))
            });

            const allVisits = [...sortedFutureData,...pastData];
            handleVisitTable(allVisits,0);
          }})
        .catch((error)=>{
          console.log(error);
          showBanner("an error occurred while fetching data, please refresh","bg-yellow-300")
        })
        .finally(()=>{
          setIsLoading(false);
        })
  }

  useEffect(()=>{
    document.title = "UCC Events";
      getAllVisits();
  },[])

    return (
      <>
        <StaffLayout>
          <div className='md:w-full w-full'>
          <div className='bg-opacity-75 rounded-2xl px-1 py-2 lg:mx-2'>
{/* overall stats */}
            <div className='transition delay-100 overflow-x-auto hover:shadow hover:border-0  rounded-2xl'>
              {/* <div className="border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5"> */}
                <div className="mx-auto grid grid-cols-4 xs:grid-cols-2 lg:grid-cols-4">
                  { visitTabs.map((tab,index)=>{
                    return (
                      <button key={index} className={`transition delay-100 shadow-sm flex items-baseline flex-wrap justify-between gap-y-1 gap-x-4 border-t border-gray-900/5 px-1 py-1 sm:px-6 lg:border-t-0 xl:px-8 ${tab.active?"bg-indigo-500 text-white":"bg-white"}`}
                       onClick={()=>{handleVisitTable(tab.content,index)}}>
                          <p className={`w-full text-sm font-medium ${tab.active?"text-white":"text-gray-500"}`}>{tab.tabName}</p>
                          <p className="w-full flex-none text-3xl font-medium leading-10 tracking-tight ">{tab.content.length} </p>
                      </button>
                    )})
                  }
                </div>
              {/* </div> */}
            </div>
{/* overall stats */}
{/* search bar */}
          <div className="px-0 sm:px-6 lg:px-8 lg:flex sm:items-center my-2 gap-x-2 gap-y-4">
              <div className='flex items-center w-full lg:w-3/4 '>
                <p className='py-2 px-3 font-semibold text-sm bg-amber-400 rounded-l-2xl shadow-sm text-gray-700'>Search UCC Events</p>
                <input 
                  value={searchValue}
                  onChange={handleSearch}
                  placeholder='search by name, date, location' 
                  className='shadow-sm block flex-1 border-0 bg-white py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 rounded-r-2xl'/>
              </div>
              <div className="sm:flex-none flex items-end lg:w-1/4 lg:my-0 md:my-0 sm:my-0 my-2">
                <Link to={`/school/counselor/new-event`} className="w-full">
                  <button
                    type="button"
                    className="w-full block rounded-lg bg-blue-500 py-2 px-5 text-center font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ">
                    New Visit
                  </button>
                </Link>
              </div>
          </div>
{/*  */}
          <div className=" flow-root hover:shadow hover:rounded-2xl">
            <div className=" -my-2 overflow-x-auto sm:-mx-8 lg:-mx-0 ">
             <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-0 ">
              <div className="overflow-hidden shadow-sm ring-1 ring-black ring-opacity-5 rounded-2xl">
              <table className="min-w-full divide-y divide-gray-700 rounded-2xl ">
                <thead className='bg-indigo-500 text-white'>
                  <tr className='text-center' >
                    <th scope="col" className="py-2 px-3 text-sm font-semibold sm:pl-0 lg:w-52 md:w-48 sm:w-32"> Event</th>
                    <th scope="col" className="hidden px-3 py-2 text-sm font-semibold lg:w-28 lg:table-cell">
                    Universities </th>
                    <th scope="col" className="hidden px-3 py-2 text-sm font-semibold lg:table-cell lg:w-40 "> 
                    Location  </th>
                    <th scope="col" className="hidden px-3 py-2 text-sm font-semibold sm:table-cell lg:w-48 ">
                    Date / Time</th>
                    <th scope="col" className="hidden px-3 py-2 text-sm font-semibold lg:w-48 drop-shadow">
                    Deadline</th>
                    <th scope="col" className="px-3 py-2 text-sm font-semibold lg:w-28">
                    Invited</th>
                    <th scope="col" className="px-3 py-2 text-sm font-semibold lg:w-28 ">
                    Attending</th>
                    <th scope="col" className="px-3 py-2 text-sm font-semibold lg:w-28 ">
                    Pending</th>
                    <th scope="col" className="px-3 py-2 text-sm font-semibold lg:w-28 ">
                    Declined</th>
                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200 rounded-b-2xl bg-white ">
                  {
                    isLoading?
                    <SkeletonTableRow colNumber={8} rowNumber={6} />
                    :   // display the data
                    visitsTable.map((visit,index) => (
                    <tr key={visit.id} className='text-center '>
                      <td className={`w-full text-start max-w-xs py-1 px-3 text-sm  font-semibold text-blue-500 sm:w-auto sm:max-w-none sm:pl-0 ${moment(visit.eventDateTime).isBefore(moment())?"text-gray-500 text-sm":""}`}>
                      <Link 
                        className="block truncate whitespace-normal break-words max-w-xs"
                        to={`/school/counselor/events/${visit.id}`} >{visit.eventName}</Link>
                        <dl className="font-normal lg:hidden">
                          <dt className="sr-only ">Event</dt>
                          <dd className="mt-1 truncate text-gray-700 ">
                          <Linkify tagName="p" options={linkifyOptions} >{visit.location}</Linkify>
                          </dd>
                        </dl>
                      </td>
                      <td className='hidden lg:table-cell whitespace-nowrap px-3 font-semibold'>
                      {visit.visiting_universities?.length}
                      </td>
                      <td className="hidden px-3 py-2 text-gray-500 lg:table-cell">
                          <p className='px-1 py-1 rounded text-sm font-semibold'>
                            <Linkify options={linkifyOptions} >{visit.location}</Linkify>
                          </p>
                      </td>
                      <td className="hidden px-3 py-2 text-sm text-gray-500 sm:table-cell">
                        <p className='px-0 py-1 rounded text-sm font-semibold'>
                        {getDateOnly(visit.eventDateTime)} / {getTimeOnly(visit.eventDateTime)}
                        </p>
                      </td>
                      <td className="hidden px-3 py-2 text-gray-500">
                          {getDateOnly(visit.eventDateTime)} / {getTimeOnly(visit.eventDateTime)}
                      </td>
                      <td className="px-4 py-2 text-sm font-semibold text-gray-500">
                        <StudentListPopOver event={visit} status={"all"} />
                      </td>
                      <td className="px-3 py-2 font-semibold text-green-600">
                        <StudentListPopOver event={visit} status={"accepted"} />
                      </td>
                      <td className="px-3 py-2 font-semibold text-orange-400">
                        <StudentListPopOver event={visit} status={"pending"} />
                      </td>
                      <td className="px-3 py-2 text-sm font-semibold text-red-600">
                        <StudentListPopOver event={visit} status={"declined"} />
                      </td>
                    </tr>
                    ))
                  }
                </tbody>
              </table>
                  </div>
                </div>
              </div>
          </div>
          </div>
        </div>
        </StaffLayout>
      </>
    )
  }
  