import React from "react";
import { Outlet } from "react-router-dom";
import useAuthenticationContext from "../hooks/authStateHook";
import UserPendingActivationSetup from "../pages/UserPendingActivation";
import ParentDisabledMessage from "../pages/ParentDisabledMessage";

export default function ParentEnableCheck(){

    const {authState} = useAuthenticationContext();

    return (
        authState.isActivated ===true ?
        <Outlet/> : <ParentDisabledMessage/>
    )
}