import React, { useContext, useEffect, useState } from 'react'
import Layout from './components/Layout'
import { AuthenticationContext } from '../services/AuthContextModule';
import moment from 'moment';

export default function Home() {
  
  const {authState} = useContext(AuthenticationContext);
  
  useEffect(()=>{
    document.title = "Schoolley | Home"
  }
  ,[]);
  return (

    <Layout>
      <div className="md:w-full w-full">
        <div className='bg-opacity-75 rounded-2xl p-3'>
          <h2 className='font-extrabold text-2xl'>Welcome Back! {authState.firstName}</h2>
          <h2 className='font-extrabold text-lg my-3'>Schoolley</h2>
          
          <p className='font-semibold text-3xl'>Have a Fabulous Day!</p>
        </div>
      </div>
    </Layout>

  )
}
