import React from 'react'
import SuperAdminLayout from '../components/SuperAdminLayout';



export default function SuperAdminTeacherProfile() {
  return (
    <SuperAdminLayout>
        <div>AdminTeacherProfile</div>
    </SuperAdminLayout>
  )
}
