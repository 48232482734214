import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout'
import { useLocation,useNavigate,useParams } from "react-router-dom";
import axiosWithInterceptor from '../../../hooks/axiosInterceptor';
import {Card,Container,Stack,Col,Row,Button} from 'react-bootstrap';
import {fsBold14,fsNoBold14} from '../../components/componentCSS'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';

export default function SuperAdminUserDetailPage() {

    const location = useLocation();
    const navigateTo = useNavigate();
    const {userId} = useParams();
    const [userInfo,setUserInfo] = useState();
    const useAxiosWithInterceptor = axiosWithInterceptor();

    const getUserInfo = async()=>{
        await useAxiosWithInterceptor.get(`/api/super-admin/users/${userId}`,{withCredentials:true})
            .then((response)=>{
                if(response.status == 200){
                    setUserInfo(response.data.payload)
                }
            }).catch((error)=>{
                console.log(error)
                navigateTo('/admin/users',{state:{from: location},replace:true})
            })
    }

    useEffect(()=>{
        getUserInfo()
    },[])
  return (
    <Layout>
    <div className='w-full px-3'>
        {userInfo?
            <>
                <Card className='my-2 shadow-sm' border='0'  style={fsNoBold14} >
                    <Card.Header className='fw-bold bg-primary bg-gradient text-white'>
                        <Stack direction='horizontal'>
                        <Col xs={11} >
                            Information
                        </Col>
                        <Col xs={1} className="d-flex justify-content-end ">
                            <Button size='sm'>
                                <FontAwesomeIcon icon={faPenToSquare} />
                            </Button>
                        </Col>
                        </Stack>
                    </Card.Header>
                    <Card.Body>
                        <Row className='mb-3'>
                            <Col xs={2} className="fw-semibold">First Name:</Col> 
                            <Col>{userInfo.firstName}</Col>
                        </Row>
                        <Row className='mb-3'>
                            <Col xs={2} className="fw-semibold">Last Name:</Col> 
                            <Col>{userInfo.lastName}</Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Card className='my-1 shadow-sm' border='0'  style={fsNoBold14}>
                    <Card.Body>
                        <Row direction='horizontal'>
                            <Col xs={2} style={fsBold14}>
                                Role:
                            </Col>
                            
                            <Col xs={4}> {userInfo.role}
                            </Col>
                        <Col xs={2} style={fsBold14}>
                            Grade:
                        </Col>
                        <Col xs={4}>
                            {userInfo.gradeLevel?userInfo.gradeLevel:"N/A"}
                        </Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col xs={2}  style={fsBold14}>Email:</Col> 
                            <Col xs={10}  style={fsBold14}>{userInfo.email}</Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col xs={2}  style={fsBold14}>School:</Col> 
                            <Col  xs={10}  style={fsBold14}>{userInfo.school?.schoolName}</Col>
                        </Row>
                    </Card.Body>
                </Card>
                    <div className='mt-4 d-flex justify-content-end'>
                    <Button className='fw-bold' size='sm' variant='danger'>Delete User</Button>
                    </div>
            </>
            :<p></p>
        }
        </div>
    </Layout>
  )
}
