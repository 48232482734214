import React, { useContext } from 'react'
import Footer from './Footer'
import { Disclosure, } from '@headlessui/react'
import { Link } from 'react-router-dom';

export default function UnAuthLayout({children}) {
  
    return (
    <>
      <div className='h-screen'>
        <Disclosure as="nav" className="bg-gray-800 shadow-lg">
          <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <Link to={'/login'}>
                <div className="flex items-center px-2 lg:px-0 gap-x-2">
                    <div className="flex-shrink-0">
                      <img
                        className="h-8 w-auto"
                        src="/logos/appLogo.png"
                        alt="Schoolley"
                      />
                    </div>
                    <p className='text-white font-semibold'>Schoolley</p>
                </div>
              </Link>
              <div className="flex items-center gap-x-4">
                <Link to={'/about'}>
                  <p className='text-white font-semibold'>About</p>
                </Link>
                <Link to={'/login'}>
                  <p className='text-white font-semibold'>Login</p>
                </Link>
              </div>
            </div>
          </div>
          {/* <NavigationBar authState={authState}/> */}
         </Disclosure>
        <main className="bg-gray-100 h-fit">
            <div className=''>{children}</div>
        </main>
        <Footer/>
      </div>
    </>
    )
}
