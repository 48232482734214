import React from 'react'
import ParentLayout from '../../components/Layouts/ParentLayout';

export default function ParentProfile() {
  return (
    <>
    <ParentLayout>
        <div className='md:w-full w-full'>
        <div className='bg-opacity-75 rounded-2xl  px-1 py-2 lg:mx-2'>
            <p>Parent Profile Page</p>
        </div>
        </div>
    </ParentLayout>
    </>
  )
}
