import React,{useState,useEffect} from 'react'
import SuperAdminLayout from '../components/SuperAdminLayout';

import axiosWithInterceptor from '../../../../hooks/axiosInterceptor';

// import { AuthenticationContext } from '../../../../services/AuthContextModule';
import { Link,useNavigate,useParams } from "react-router-dom";
import StudentArchiveOptionBar from './components/StudentArchiveOptionBar';
import { displayArchiveStatus } from '../../../../utils/displayArchiveStatus';
import ActivationButton from '../../adminComponents/SuperAdmin_ActivationButton';

export default function SuperAdminManageSchoolStudents() {

    // const {authState} = useContext(AuthenticationContext);
    const useAxiosWithInterceptor = axiosWithInterceptor();
    // const location = useLocation();
    const navigateTo = useNavigate();
    // const userId = authState.user;
    const {schoolId} = useParams();
    
    const [schoolInfo,setSchoolInfo] = useState();
    const [allStudents,setAllStudents] = useState([]);
    const [allGrades,setAllGrades] = useState([]);
    const [studentsTable,setStudentsTable] = useState([]);

    const [searchValue,setSearchValue] = useState("");
    
    const handleSearch = (e)=>{
      setSearchValue(e.target.value);
      let filteredStudents = allStudents.filter((student)=>{
        const searchKey = ["firstName","lastName","email","gradeLevel","status"];

        return searchKey.some((key)=>{
          if(key === "gradeLevel"){
            if(student[key].toString().includes(e.target.value)){
              return student;
            }
          } 
          else if (typeof student[key]==="string" && student[key].toLowerCase().includes(e.target.value.toLowerCase())){
            return student;
          }
        })
      })
      setStudentsTable(filteredStudents);
    }

    const getSchool = async()=>{
        await useAxiosWithInterceptor.get(`/api/super-admin/schools/${schoolId}`,{withCredentials:true})
          .then((response)=>{
            if(response.status === 200){
              setSchoolInfo(response.data.payload);
            }
          }
          ).catch((err)=>{
            navigateTo(-1);
          });
      }
    
    const getAllStudents = async()=>{
        await useAxiosWithInterceptor.get(`/api/super-admin/schools/${schoolId}/students`,{withCredentials:true})
        .then((response)=>{
          if(response.status === 200){
            // sort by student first name
            const sortedStudents = response.data.payload.sort((a,b)=>{
              if(a.firstName < b.firstName){
                return -1;
              }
              if(a.firstName > b.firstName){
                return 1;
              }
              return 0;
            });
            setAllStudents(sortedStudents);
            setStudentsTable(sortedStudents);
            getAllGrades(sortedStudents);
          }
        }).catch((error)=>{
            console.log(error)
        });
    }

    const getAllGrades = async(students)=>{
        const gradeLevels = students.map((student)=>student.gradeLevel);
        const uniqueGrades = [...new Set(gradeLevels)];
        setAllGrades(uniqueGrades.sort((a,b)=>a-b));
        // console.log(uniqueGrades);
    }

    useEffect(()=>{
        getSchool();
        getAllStudents();
    },[]);

  return (
    <SuperAdminLayout>
    <div className='md:w-full w-full'>
        <div className='bg-opacity-75 rounded-2xl bg-white px-3 py-3'>
              <div className='flex mx-2 justify-between'>
                <p className='text-xl font-bold py-1 px-2'>
                  <span className='text-blue-500 text-3xl'>{schoolInfo?.schoolName}</span> Students
                </p>
                <button className='transition delay-500 text-white shadow-sm bg-cyan-500 lg:px-4 sm:px-1 xs:px-1 py-1 my-1 rounded font-semibold'>Roll Over</button>
              </div>
            {
              allGrades.length > 0 &&
              <div className='flex flex-wrap mx-2'>
                {
                  allGrades.map((gradeLevel)=>{
                    return (
                      <Link key={gradeLevel} to={`/admin/schools/${schoolId}/manage-students/grade/${gradeLevel}`}>
                        <button className='transition delay-50 text-white shadow-sm bg-blue-500 hover:bg-sky-400 text-lg border-1 px-4 py-1 my-1 font-semibold rounded'>{gradeLevel}</button>
                      </Link>
                    )
                  })
                }
              </div>
            }

            <div className="px-0 sm:px-6 lg:px-8 lg:flex sm:items-center my-2 gap-x-2">
              <div className='flex items-center w-full '>
                <p className='py-2 px-3 font-semibold text-sm bg-amber-400 rounded-l-2xl shadow-sm text-gray-700'>Search Students</p>
                <input 
                  value={searchValue}
                  onChange={handleSearch}
                  placeholder='search by student name, grades, email, status' 
                  className='shadow-sm block flex-1 border-0 bg-gray-100 py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 rounded-r-2xl'/>
              </div>
          </div>
          <div className='mt-2 flow-root shadow-sm rounded-2xl overflow-x-auto'>
            <div className='inline-block min-w-full align-middle'>
              <table className='min-w-full border-separate border-spacing-0'>
                <thead className=''>
                  <tr className='text-center bg-green-500'>
                    <th className='sticky top-0 border-b border-gray-300 bg-white bg-opacity-75 py-2.5 pl-4 pr-3 text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8 rounded-tl-2xl'>Name</th>
                    <th className='sticky top-0  border-b border-gray-300 bg-white bg-opacity-75 px-3 py-2.5 text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell'>Email</th>
                    <th className='sticky top-0  border-b border-gray-300 bg-white bg-opacity-75 px-3 py-2.5 text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell '>Grade</th>
                    <th className='sticky top-0  border-b border-gray-300 bg-white bg-opacity-75 px-3 py-2.5 text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell'>Status</th>
                    <th className='sticky top-0  border-b border-gray-300 bg-white bg-opacity-75 px-3 py-2.5 text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell '>Actions</th>
                    <th className='sticky top-0  border-b border-gray-300 bg-white bg-opacity-75 px-3 py-2.5 text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell rounded-tr-2xl'>Archive</th>
                  </tr>
                </thead>
                <tbody>
                {studentsTable.map((student)=>{
                    return (
                    <tr key={student.id} className='shadow-sm text-center'>
                        <td className='whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8'>{student.firstName} {student.lastName}</td>
                        <td className='whitespace-nowrap py-1 px-2 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8'>{student.email}</td>
                        <td className='whitespace-nowrap py-1 px-2 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8'>{student.gradeLevel}</td>
                        <td className='whitespace-nowrap py-1 px-2 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8'>{displayArchiveStatus(student.archived)}</td>
                        <td className='whitespace-nowrap py-1 px-2 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8'>
                          <ActivationButton studentInfo={student} refreshStudents={getAllStudents} />
                        </td>
                        <td className='whitespace-nowrap py-2 px-2 font-medium text-gray-900 sm:pl-6 lg:pl-8'>
                        <StudentArchiveOptionBar studentId={student.id} refreshStudents={getAllStudents} />
                        </td>
                    </tr>
                    )
                    })
                }
                </tbody>
              </table>
            </div>
          </div>
        </div>
    </div>
    </SuperAdminLayout>
  )
}