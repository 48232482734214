import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout';
import SuperAdminLayout from '../components/SuperAdminLayout';

import { Link, useParams } from "react-router-dom";
import axiosWithInterceptor from '../../../../hooks/axiosInterceptor';
import moment from 'moment';

export default function SuperAdminSchoolEvents() {
    const useAxiosWithInterceptor = axiosWithInterceptor();
    const {schoolId} = useParams();
    const [schoolInfo,setSchoolInfo] = useState({});

    const [events,setEvents] = useState([]);

    const [searchValue,setSearchValue] = useState("");
    const [eventsTable,setEventsTable] = useState([]);

    const handleSearch = (e)=>{
        setSearchValue(e.target.value);
        let filteredEvents = events.filter((event)=>{
            const searchKey = ["eventName","eventDateTime"];
            return searchKey.some((key)=>{
                if(key === "eventDateTime"){
                    const date = moment(event[key]).format('MMMM Do, YYYY');
                    if(date.toLowerCase().includes(e.target.value.toLowerCase())){
                        return event;
                    }
                } else if (typeof event[key] === "string" && event[key].toLowerCase().includes(e.target.value.toLowerCase())){
                    return event;
                }
            })
        })
        setEventsTable(filteredEvents);
    }

    const getEvents = async()=>{
        await useAxiosWithInterceptor.get(`/api/super-admin/schools/${schoolId}/events`,{withCredentials:true})
        .then((response)=>{
            if(response.status === 200){
                const sortedEvents = response.data.payload.sort((a,b)=>{
                    return new Date(b.eventDateTime) - new Date(a.eventDateTime);
                    }
                )
                setEvents(sortedEvents);
                setEventsTable(sortedEvents);
                setSchoolInfo(response.data.schoolInfo);
                console.log(response.data.schoolInfo);

            }
        }).catch((error)=>{
            console.log(error)
        });
    }

    useEffect(()=>{
        getEvents();
    }
    ,[])

  return (
    <SuperAdminLayout>
    <div className='md:w-full w-full'>
        <div className='bg-opacity-75 rounded-lg lg:mx-2'>
            <p className='text-xl font-semibold my-2'>{schoolInfo.schoolName}</p>
            <div className="px-0 sm:px-6 lg:px-8 lg:flex sm:items-center my-2 gap-x-2">
              <div className='flex items-center w-full'>
                <p className='py-2 px-3 font-semibold text-sm bg-amber-400 rounded-l-2xl shadow-sm text-gray-700'>Search Events</p>
                <input 
                  value={searchValue}
                  onChange={handleSearch}
                  placeholder='search by name, date, location' 
                  className='shadow-sm block flex-1 border-0 bg-white py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 rounded-r-2xl'/>
              </div>
            </div>
            <div className='shadow-md ring-1 ring-gray-900/5 rounded-lg bg-white overflow-x-auto'>
            <table className="w-full whitespace-nowrap text-left text-sm leading-6 ">
                <thead className="border-b border-gray-200 text-gray-900">
                  <tr className='text-center bg-blue-500 text-white'>
                    <th className="py-2 font-semibold">Event</th>
                    <th className="py-2 font-semibold">Date</th>
                    <th className="hidden py-2 font-semibold sm:table-cell">Invited</th>
                    <th className="hidden py-2 font-semibold sm:table-cell">Accepted</th>
                    <th className="hidden py-2 font-semibold sm:table-cell">Declined</th>
                    <th className="hidden py-2 font-semibold sm:table-cell">Pending</th>
                  </tr>
                </thead>
                <tbody>
                {
                    eventsTable.map((event)=>{
                        return (
                            <tr key={event.id} className='text-center'>
                                <td className='py-2 text-left w-80 text-wrap pl-4 font-semibold '>
                                <Link to={`/admin/schools/${schoolId}/events/${event.id}`} className='text-blue-500'>{event.eventName}</Link>
                                </td>
                                <td className='py-2'>{moment(event.eventDateTime).format('MMMM Do, YYYY')}</td>
                                <td className='hidden py-2 sm:table-cell'>{event.countInvited}</td>
                                <td className='hidden py-2 sm:table-cell'>{event.countAccepted}</td>
                                <td className='hidden py-2 sm:table-cell'>{event.countDeclined}</td>
                                <td className='hidden py-2 sm:table-cell'>{event.countPending}</td>
                            </tr>
                        )
                    })
                }
                </tbody>
            </table>
          </div>
        </div>
    </div>
    </SuperAdminLayout>
  )
}
