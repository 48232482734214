import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout';
import SuperAdminLayout from '../components/SuperAdminLayout';

import { Link, useLocation,useNavigate,useParams } from "react-router-dom";
import axiosWithInterceptor from '../../../../hooks/axiosInterceptor';
import { displayRoles } from '../../../../utils/displayRoles';
import moment from 'moment';
import ProfileAvatar from '../../../components/Avatar';

export default function SuperAdminSchoolProfile() {
  const useAxiosWithInterceptor = axiosWithInterceptor();
  const location = useLocation();
  const navigateTo = useNavigate();
  const {schoolId} = useParams();

  const [schoolInfo,setSchoolInfo] = useState();
  const [events, setEvents] = useState([]);
  const [teachers,setTeachers] = useState([]);
  const [students, setStudents] = useState([]);

  const [teachersTable, setTeachersTable] = useState([]);
  const [studentsTable, setStudentsTable] = useState([]);

  const [studentsSearchValue,setStudentsSearchValue] = useState("");
  const [teachersSearchValue,setTeachersSearchValue] = useState("");

  const handleTeachersTable = (teacherData)=>{
    setTeachersTable(teacherData)
  }
  const handleStudentsTable = (studentData)=>{
    setStudentsTable(studentData)
  }

  const handleSearchTeachers = (e)=>{
    const searchValue = e.target.value;
    const filteredTeachers = teachers.filter((teacher)=>{
      return teacher.firstName.toLowerCase().includes(searchValue.toLowerCase()) || teacher.lastName.toLowerCase().includes(searchValue.toLowerCase())
    })
    handleTeachersTable(filteredTeachers)
  }

  const getSchool = async()=>{
    await useAxiosWithInterceptor.get(`/api/super-admin/schools/${schoolId}`,{withCredentials:true})
      .then((response)=>{
        if(response.status === 200){
          setSchoolInfo(response.data.payload)
        }
      }
      ).catch((err)=>{
        setSchoolInfo()
        navigateTo('/admin/schools',{state:{from: location},replace:true});
      });
  }

  const getTeachers = async()=>{
    await useAxiosWithInterceptor.get(`/api/super-admin/schools/${schoolId}/teachers`,{withCredentials:true})
    .then((response)=>{
      if(response.status === 200){
        setTeachers(response.data.payload)
      }
    }
    ).catch((err)=>{
      console.log(err)
    });
  }

  const getStudents = async()=>{
    await useAxiosWithInterceptor.get(`/api/super-admin/schools/${schoolId}/students`,{withCredentials:true})
    .then((response)=>{
      if(response.status === 200){
        let studentByGrades = response.data.payload.sort((a,b)=>a.gradeLevel - b.gradeLevel)
        setStudents(studentByGrades)
      }
    }
    ).catch((err)=>{
      console.log(err)
    });
  }

  const getEvents = async()=>{
    await useAxiosWithInterceptor.get(`/api/super-admin/schools/${schoolId}/events`,{withCredentials:true})
    .then((response)=>{
      if(response.status === 200){
        setEvents(response.data.payload)
      }
    }
    ).catch((err)=>{
      console.log(err)
    });
  }
    
    useEffect(()=>{
        getSchool();
        getTeachers();
        getStudents();
        getEvents();
    },[])

  return (
    <SuperAdminLayout>
    <div className='md:w-full w-full'>
      <div className='bg-opacity-75 rounded-lg lg:mx-2'>
        <div className="mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-4 lg:mx-0 lg:max-w-none lg:grid-cols-3 relative">
          
          <div id='school-side-column' className="lg:col-start-3 lg:row-end-1 ">
            <div className="rounded-lg shadow-md ring-1 ring-gray-900/5 bg-white">
              <div className="flex flex-col px-3 py-3 gap-y-5">
                <div className='flex flex-col gap-y-2'>
                  <div className='flex gap-x-5 text-sm '>
                    <p className="font-semibold ">Students Total</p>
                    <p className=" font-semibold text-gray-900">{students?.length}</p>
                  </div>
                  <div className='flex gap-x-5 px-3 justify-around'>
                    <div className='flex gap-x-5 text-green-600 text-sm '>
                      <p className="font-semibold ">Active </p>
                      <p className="font-semibold">{students?.filter((student)=>student.archived === false).length}</p>
                    </div>
                    <div className='flex gap-x-5 text-red-700 text-sm '>
                      <p className="font-semibold">Inactive</p>
                      <p className="font-semibold ">{students?.filter((student)=>student.archived === true).length}</p>
                    </div>
                  </div>
                </div>
                <div className='flex flex-col gap-y-2'>
                  <div className='flex gap-x-5'>
                    <p className="font-semibold text-sm">Teacher Total</p>
                    <p className="font-semibold text-gray-900">{teachers?.length}</p>
                  </div>
                  <div className='flex gap-x-5 px-3 justify-around text-sm '>
                    <div className='flex gap-x-5 text-green-600'>
                      <p className="font-semibold ">Active </p>
                      <p className=" font-semibold">{teachers?.filter((teacher)=>teacher.archived === false).length}</p>
                    </div>
                    <div className='flex gap-x-5 text-red-700 text-sm '>
                      <p className="font-semibold">Inactive</p>
                      <p className=" font-semibold ">{teachers?.filter((teacher)=>teacher.archived === true).length}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
                <h2 className="text-base font-semibold leading-3 text-gray-900 my-2 py-2">Subscription</h2>
                <div className="lg:col-start-3 rounded-lg">
                  <div className='bg-white shadow-md rounded-lg ring-1 ring-gray-900/5 p-3 mb-2'>
                    <p>Current Subscription</p>
                  </div>
                  
                  <div className='my-4 '>
                    <Link to={`/admin/schools/${schoolId}/manage-subscription`} >
                      <button className='transition delay-50 bg-indigo-500 text-white font-semibold px-3 py-2 rounded hover:bg-indigo-600 '>Manage Subscription</button>
                    </Link>
                  </div>
                </div>
              </div>
            <div className="lg:col-start-3 rounded-lg ">
              <Link to={`/admin/schools/${schoolId}/events`}>
                <button className="text-base font-semibold w-full leading-3 py-2.5 text-white bg-blue-500 rounded-lg px-3 my-1 hover:bg-blue-600 cursor-pointer">Events</button>
              </Link>
              
              <div className='shadow-md px-3 py-3 rounded-lg bg-white overflow-y-auto'>
                <ul role="list" className="mt-1 space-y-6 ">
                  {events.map((event) => (
                    <li key={event.id}>
                      <div className="flex justify-between">
                        <p className="text-sm font-semibold text-gray-900">{event.eventName}</p>
                        <p className="text-sm font-semibold text-gray-500">
                        {
                          moment(event.eventDateTime).format("MM - DD -YYYY")
                          }
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
{/* SCHOOL INFO BODY */}
          <div className="sm:mx-0 sm:rounded-lg lg:col-span-2 lg:row-span-2 lg:row-end-2 ">
            <div className='shadow-md ring-1 ring-gray-900/5 p-3 rounded-lg bg-white grid grid-cols-2'>
              <div className="my-2 text-sm leading-6 sm:grid-cols-2 ">
                <h2 className="text-2xl font-semibold leading-6 text-gray-900">{schoolInfo?.schoolName.toUpperCase()}</h2>
                <div className="py-2">
                  <p className="inline text-gray-500 font-semibold text-gray-900">Joined on</p>
                  <div className=" text-gray-500 ml-2">
                    <p className='my-1 py-0 font-semibold text-gray-900'>{moment(schoolInfo?.createdAt).calendar()}</p>
                  </div>
                </div>
                <div className="border-t border-gray-900/5">
                  <p className="font-semibold text-gray-900">Address</p>
                  <div className="mt-2 text-gray-500 ml-2">
                    <p className="font-semibold text-gray-900">{schoolInfo?.address}</p>
                    <p className='my-1 py-0 font-semibold text-gray-900'>{schoolInfo?.city}</p>
                    <p className='my-1 py-0 font-semibold text-gray-900'>{schoolInfo?.country}</p>
                  </div>
                </div>
              </div>
              <div className='my-2'>
                <div className='sm:col-span-1 grid justify-items-center'>
                  <div className='h-48 w-48 my-2'>
                    <img
                      className={`object-cover inline-block h-full w-full object-cover rounded-full shadow-md border-2 border-teal-50`}
                      src={schoolInfo?.profilePicture}           
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
{/* school staff section */}
            <div className='my-3 px-0 sm:px-6 lg:px-8 sm:flex sm:items-center my-2 gap-x-2 '>
              <div className='flex items-center w-3/4 py-2 '>
                <p className='py-2 px-5 font-semibold text-sm bg-amber-400 rounded-l-2xl shadow-sm text-gray-700'>TEACHERS</p>
                <input 
                  // value={searchValue}
                  onChange={handleSearchTeachers}
                  placeholder='search teachers' 
                  className='shadow-sm block flex-1 border-0 bg-slate-150 py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 rounded-r-lg'/>
              </div>
              <div className='flex items-center justify-end w-1/4 py-2 '>
                <Link to={`/admin/schools/${schoolId}/manage-admin`} >
                  <button className='px-10 py-2 bg-indigo-500 rounded-lg text-white font-semibold text-sm'>Manage</button>
                </Link>
              </div>
            </div>

            <div className='shadow-md ring-1 ring-gray-900/5 rounded-lg bg-white overflow-x-auto'>
              <table className="w-full whitespace-nowrap text-left text-sm leading-6 ">
                <thead className="border-b border-gray-200 text-gray-900">
                  <tr className='text-center'>
                    <th className="py-2 font-semibold">Name </th>
                    <th className="py-2 font-semibold">Email</th>
                    <th className="hidden py-2 font-semibold sm:table-cell">Role</th>
                    <th className="hidden py-2 font-semibold sm:table-cell">Created</th>
                  </tr>
                </thead>
                <tbody>
                  {teachers.map((teacher) => (
                    <tr key={teacher.id} className="border-b border-gray-100 text-center">
                      <td className="pl-3 py-2 font-medium text-gray-900 text-left">
                        <div className='flex gap-2 items-center'>
                          <ProfileAvatar size={10} user={teacher} textSize={5} />
                          <p>{teacher.firstName} {teacher.lastName}</p>
                        </div>
                      </td>
                      <td className="pl-3 py-2 pr-0 text-gray-700 sm:table-cell ">{teacher.email}
                      </td>
                      <td className="hidden pl-3 py-2 pr-0 text-gray-700 sm:table-cell ">
                      {displayRoles(teacher.role)}
                      </td>
                      <td className="hidden pl-3 py-2 pr-0 text-center text-gray-700 sm:table-cell text-left">
                        {moment(teacher.createdAt).format("MM - DD - YYYY")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className='my-3 px-0 sm:px-6 lg:px-8 sm:flex sm:items-center my-2 gap-x-2 '>
              <div className='flex items-center w-3/4 py-2 '>
                <p className='py-2 px-5 font-semibold text-sm bg-green-400 rounded-l-2xl shadow-sm text-gray-700'>STUDENTS</p>
                <input 
                  placeholder='search students' 
                  className='shadow-sm block flex-1 border-0 bg-slate-150 py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 rounded-r-lg'/>
              </div>
              <div className='flex items-center justify-end w-1/4 py-2'>
                <Link to={`/admin/schools/${schoolId}/manage-students`} >
                  <button className='px-10 py-2 bg-indigo-500 rounded-lg text-white font-semibold text-sm'>Manage</button>
                </Link>
              </div>
            </div>
            <div className='shadow-md ring-1 ring-gray-900/5 rounded-lg bg-white overflow-x-auto'>
              <table className="w-full whitespace-nowrap text-sm leading-6 ">
                <thead className="border-b border-gray-200 text-gray-900">
                  <tr className='text-center'>
                    <th scope="col" className="px-1 py-2 font-semibold"> Name</th>
                    <th scope="col" className="p-2 font-semibold">Email</th>
                    <th scope="col" className="hidden p-2 font-semibold sm:table-cell">Grade</th>
                    <th scope="col" className="hidden p-2 font-semibold sm:table-cell">Created At </th>
                    <th scope="col" className="p-2 font-semibold">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {students.map((student) => (
                    <tr key={student.id} className="border-b border-gray-100 text-center">
                      <td className="pl-3 py-2 font-medium text-gray-900 text-left">
                        <div className='flex gap-2 items-center'>
                          <ProfileAvatar size={10} user={student} textSize={5} />
                          <p>{student.firstName} {student.lastName}</p>
                        </div>
                      </td>
                      <td className="pl-2 text-gray-700">
                        {student.email}
                      </td>
                      <td className="hidden pr-0 text-gray-700 text-center sm:table-cell font-semibold">
                        {student.gradeLevel}
                      </td>
                      <td className="hidden py-2 pr-0 text-gray-700 text-center sm:table-cell text-left">
                        {moment(student.createdAt).format("MM-DD-YYYY")}
                      </td>
                      <td >
                        {student.activated ?
                        <p className='font-semibold text-green-600'>Activated</p>
                          :
                        <p className='font-semibold text-amber-600'>Not Activated</p>}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

         
            {/* manage subscription */}
        </div>
      </div>
    </div>
    </SuperAdminLayout>
  )
}

