import React, { useEffect } from 'react';
import UnAuthLayout from './components/UnAuthLayout';

export default function PrivacyPolicies() {
  
  useEffect(() => {
    document.title = "Schoolley | Privacy Policy";
  }, []);
  
  return (
    <UnAuthLayout>
    <div className="md:w-full w-full">
    <div className='max-w-4xl mx-auto p-6 bg-white rounded-lg mt-6'>
        <h1 className="text-3xl font-bold mb-4 text-center">Privacy Policy</h1>
        <p className="text-gray-600 mb-4">
          <strong>Last Updated:</strong> 
          2024-09-01
        </p>

      <p className="text-gray-800 mb-6">
        At <strong>Schoolley</strong>, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, and share data from users of our website and services. As a Software-as-a-Service (SaaS) platform, we want to make it clear that each school using our platform has exclusive access to their own students' information, and no other party, including other schools, can access this data.
      </p>

      <h2 className="text-2xl font-semibold mb-2">1. Information We Collect</h2>
      <p className="text-gray-800 mb-4">We may collect the following types of information:</p>
      <ul className="list-disc pl-5 text-gray-800 mb-6">
        <li><strong>Personal Information</strong>: When you sign up or use our services, we may collect personal information such as your name, email address, and other identifying information.</li>
        <li><strong>Usage Data</strong>: We may collect data about how you interact with our website, including your IP address, browser type, pages visited, and time spent on the site.</li>
        <li><strong>Cookies</strong>: We use cookies to track user activity and enhance your experience on our website. You can adjust your browser settings to block or remove cookies.</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-2">2. How We Use Your Information</h2>
      <p className="text-gray-800 mb-4">We use the data we collect for the following purposes:</p>
      <ul className="list-disc pl-5 text-gray-800 mb-6">
        <li><strong>To provide and improve our services</strong>: We use personal information to authenticate users, manage events, and provide customer support.</li>
        <li><strong>To send transactional communications</strong>: We may use your email address to send you notifications, such as confirmation emails or updates on your events.</li>
        <li><strong>To analyze and enhance user experience</strong>: We may use aggregated usage data to improve the functionality and performance of our website and services.</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-2">3. Sharing Your Information</h2>
      <p className="text-gray-800 mb-4">
        We do not sell or rent your personal information to third parties. However, we may share your information with trusted third-party services to help us operate our app and provide services. This includes:
      </p>
      <ul className="list-disc pl-5 text-gray-800 mb-6">
        <li><strong>Google OAuth</strong>: We use Google OAuth for user authentication. Please refer to <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">Google’s Privacy Policy</a> for more information.</li>
        <li><strong>Mailgun</strong>: We use Mailgun to send emails. Please refer to <a href="https://www.mailgun.com/privacy-policy" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">Mailgun’s Privacy Policy</a> for more information.</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-2">4. Data Security</h2>
      <p className="text-gray-800 mb-6">
        We take the security of your data seriously and implement reasonable measures to protect it from unauthorized access or disclosure. However, please note that no method of transmission over the Internet or electronic storage is 100% secure.
      </p>

      <h2 className="text-2xl font-semibold mb-2">5. Your Data Rights</h2>
      <p className="text-gray-800 mb-4">You have the right to:</p>
      <ul className="list-disc pl-5 text-gray-800 mb-6">
        <li><strong>Access</strong> the personal information we hold about you.</li>
        <li><strong>Correct</strong> any inaccuracies in your data.</li>
        <li><strong>Request deletion</strong> of your personal information, subject to legal requirements.</li>
      </ul>
      <p className="text-gray-800 mb-6">
        If you would like to exercise any of these rights, please contact us at <span className='font-semibold text-blue-500'>support@schoolley.com</span> 
      </p>

      <h2 className="text-2xl font-semibold mb-2">6. Changes to this Privacy Policy</h2>
      <p className="text-gray-800 mb-6">
        We may update this Privacy Policy from time to time. If we make significant changes, we will notify you by email or by posting a notice on our website.
      </p>

      <h2 className="text-2xl font-semibold mb-2">7. Contact Us</h2>
      <p className="text-gray-800">
        If you have any questions or concerns about this Privacy Policy, please contact us at:
      </p>
      <p className="text-gray-800">
        Email: <span className='font-semibold text-blue-500'>contact@schoolley.com</span><br/>
        Website: <a href="https://schoolley.com" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">https://www.schoolley.com</a>
      </p>
        </div>
      </div>
    </UnAuthLayout>
  );
}
