import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useLocation, Link } from 'react-router-dom';
import UnAuthLayout from './components/UnAuthLayout';
import axios from '../api/axios';

export default function StudentActivationBuffer() {
    const {activationToken} = useParams();
    const navigateTo = useNavigate();
    const [loadSpinner, setLoadSpinner] = useState('none');
    const [activationMessage, setActivationMessage] = useState('');

    const [success, setSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [requestSend, setRequestSend] = useState(false);

    const [activeCheckReqSent, setActiveCheckReqSent] = useState(false);
    const [alreadyActivated, setAlreadyActivated] = useState(false);

    const checkActivationStatus = async ()=>{
        setLoadSpinner('block');
        await axios.get(`/api/auth/activate/parent/${activationToken}`,{withCredentials:true})
        .then((response)=>{
            if(response.status === 200){
                setAlreadyActivated(true);
                setTimeout(()=>{
                    navigateTo('/');
                },2000);
            }
        })
        .catch((error)=>{
            console.log(error)
        })
        .finally(()=>{
            setLoadSpinner('none');
            setIsLoading(false);
        })
    }

    const [passwords, setPasswords] = useState({ password: '', confirmPassword: '' });
    const [passwordMatch, setPasswordMatch] = useState(false);

    const [validated,setValidated] = useState(false);

    const handlePasswordChange = (e) => {
        const { name, value } = e.target;
        setPasswords((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleActivation = async (e)=>{
        setLoadSpinner('block');
        e.preventDefault();
        if(e.currentTarget.checkValidity() === false){
            e.stopPropagation();
            setValidated(true)
        } else{
            const passwordForm = new FormData();
            passwordForm.append('password',passwords.password);
            passwordForm.append('confirmPassword',passwords.confirmPassword);
            setRequestSend(true);
        // this might need to be a POST request
            await axios.post(`/api/auth/activate/student/${activationToken}`,passwordForm,{withCredentials:true})
            .then((response)=>{
                if(response.status === 200){
                    setSuccess(true);
                    setActivationMessage('Your account is activated');
                    setTimeout(()=>{
                        navigateTo('/');
                    },3000)
                }
            })
            .catch((error)=>{
                console.log(error)
            })
            .finally(()=>{
                setLoadSpinner('none');
                setIsLoading(false);
            })
        }
    }

    useEffect(() => {
        if(passwords.password !== '' || passwords.confirmPassword !== ''){
            setPasswordMatch(passwords.password === passwords.confirmPassword);
        }
    }, [passwords]);

    useEffect(()=>{
        checkActivationStatus();
    },[]);

  return (
    <>
    <UnAuthLayout>
    <div className='flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8'>
        { alreadyActivated?
                <div className='text-center'>
                    <h2 className='text-2xl font-bold text-gray-900'>Your account is already activated</h2>
                    <p>You will be redirected to login page or home page</p>
                    <p>if not, please click <span className='font-semibold text-blue-500'>
                        <Link to={'/'}>
                            Here
                        </Link>
                        </span>
                    </p>
                </div>
                :   // if account is not activated
                <div>
                    <div className='sm:mx-auto sm:w-full sm:max-w-[480px] rounded p-4 mt-5 mb-2'>
                <p className='text-3xl font-semibold text-indigo-500 text-center'>Activate Your Schoolley Account</p>
                    </div>
                    <div id='password-setup' className='sm:mx-auto sm:w-full sm:max-w-[480px] bg-white rounded px-4 py-8 shadow mb-4' >
                    {
                        requestSend?
                        <div className="text-center">
                            <h2 className='text-2xl font-bold text-gray-900'>Activating your account...</h2>
                            <svg className="animate-spin h-5 w-5 mx-auto mt-4" viewBox="0 0 24 24">
                                {/* Spinner SVG */}
                            </svg>
                        </div>
                        :
                        success?
                        <div className='text-center '>
                            <h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-900">
                            Your account is activated
                            </h2>
                            <p>You will be redirected to the home page in 3 seconds</p>
                            <p>if not, please click here</p>
                            <Link to={'/'}>
                                <p>Home</p>
                            </Link>
                        </div>
                        :       // if request haven't sent
                        <div>
                            <h1 className='text-2xl font-bold text-gray-900'>Set up your password</h1>
                            <form onSubmit={handleActivation}>
                                <div className='m-3 space-y-2'>
                                    <p className='font-semibold'>Password</p>
                                    <input 
                                        type="password" 
                                        placeholder="Password" 
                                        name='password'
                                        value={passwords.password} 
                                        className='block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-inset placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                                        onChange={handlePasswordChange} />
                                </div>
                                <div className='m-3 space-y-2'>
                                    <p className='font-semibold'>Confirm Password</p>
                                    <input 
                                    type="password" 
                                    placeholder="Confirm Password" 
                                    name='confirmPassword'
                                    value={passwords.confirmPassword} 
                                    className='block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-inset placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                                    onChange={handlePasswordChange}
                                    />
                                </div>
                            </form>
                            {
                                passwordMatch?
                                <button className='bg-blue-500 text-white rounded-md p-2 w-full font-semibold' onClick={handleActivation}>Activate Account</button>
                                :   // if password not matched
                                <button className='bg-gray-300 text-gray-500 rounded-md p-2 w-full font-semibold' disabled>Activate Account</button>
                            }
                        </div>
                    }
                        
                    </div>
                </div>
            }
    </div>
    </UnAuthLayout>
    </>
  )
}
