import React, { useState,useContext } from 'react';
import { AuthenticationContext } from '../../../services/AuthContextModule';
import axiosWithInterceptor from '../../../hooks/axiosInterceptor';
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react'
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { Link, useNavigate } from 'react-router-dom';

export default function ParentProfileWidget() {
    const {authState, setAuthState} = useContext(AuthenticationContext);
    const useAxiosWithInterceptor = axiosWithInterceptor();
    const navigateTo = useNavigate();
    const userRole = authState.role;
    const userId = authState.user;

    const handleLogOut= async ()=>{
        await useAxiosWithInterceptor.get("/api/auth/logout")
            .then((response)=>{
                if(response.status == 200){
                    setAuthState({
                        // accessToken: "",
                        // user:"",
                        // role:"",
                        // isAuthenticated:false,
                        // isActive:false
                    });
                }
                navigateTo('/login');
              })
            .catch((e)=>{
              console.log(e)
        })
      }
  return (
    <>
    <Menu as="div" className="relative">
        <Menu.Button className="-m-1.5 flex items-center p-1.5">
            <span className="sr-only">Open user menu</span>
            {   
            authState?.profilePicture? 
                <img
                    className="h-8 w-8 rounded-full bg-gray-50 object-cover"
                    src={authState.profilePicture}
                    alt=""/>
                :
                <div className='inline-block h-8 w-8 flex items-center justify-center bg-gray-200 rounded-full shadow-md border-2 border-teal-50 text-xl font-semibold text-gray-700'>
                    {authState.firstName[0]}
                </div>
            }
            <span className="hidden lg:flex lg:items-center">
            <span className="ml-4 text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">
                {authState.firstName}
            </span>
            <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
        </Menu.Button>
        <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95" >

            <Menu.Items className="absolute right-0 z-10 mt-2.5 w-44 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none space-y-2">

            <Link to={`/parent/contact`}>
                <Menu.Item className='w-full py-2 mb-2 hover:bg-violet-500 hover:text-white '>
                <p className='text-center text-sm font-semibold'>Contact Us</p>
                </Menu.Item>
            </Link>
            
            <Link to={`/parent/profile`}>
                <Menu.Item className='w-full py-2 mb-2 hover:bg-violet-500 hover:text-white '>
                <p className='text-center text-sm font-semibold'>Profile</p>
                </Menu.Item>
            </Link>

            <Menu.Item className='hover:bg-violet-500 py-2 mb-2 hover:text-white bg-amber-500 '>
                <p className='text-center cursor-pointer text-sm font-semibold' onClick={handleLogOut}>Log Out</p>
            </Menu.Item>
            </Menu.Items>
        </Transition>
    </Menu>
  </>
  )
}
