import React, { useEffect, useState,useContext } from 'react'
import StaffLayout from '../../components/Layouts/StaffLayout';

import { Link, useLocation,useNavigate,useParams } from "react-router-dom";
import axiosWithInterceptor from '../../../hooks/axiosInterceptor';
import { AuthenticationContext } from '../../../services/AuthContextModule';
import UserEditPhoto from '../../components/UserEditPhoto';
import ProfileAvatar from '../../components/Avatar';
import { useBanner } from '../../../services/SystemBannerMessage';
import { PencilIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import StudentsGradeOptions from '../administrator/administratorComponents/StudentsGradeOptions';

// add a feature to see categories of events student has participated

export default function SchoolAllStaffProfile() {
    const useAxiosWithInterceptor = axiosWithInterceptor();
    const {authState} = useContext(AuthenticationContext);
    const userId = authState.user;
    const schoolId = authState.schoolId;

    const location = useLocation();
    const navigateTo = useNavigate();
    const {showBanner} = useBanner();

    const [staffInfo,setNewStaffInfo] = useState({});

    const [events, setEvents] = useState([]);
    const [attending, setAttending] = useState([]);
    const [declined, setDeclined] = useState([]);
    const [pending, setPending] = useState([]);
    

    const getStudentInfo = async()=>{
        await useAxiosWithInterceptor.get(`/api/user/${userId}/school/${schoolId}/staff-profile`,{withCredentials:true})
            .then((response)=>{
                if(response.status === 200){
                    setNewStaffInfo(response.data.payload);
                    setEvents(response.data.payload.events);
                }
            }).catch((error)=>{
                showBanner("Student Not Found")
            })
    }

    useEffect(()=>{
        getStudentInfo();
        // getInvitations();
    },[]);

    useEffect(()=>{
        document.title = staffInfo?.firstName +" "+ staffInfo?.lastName
    },[staffInfo])
  
    return (
    <>
    <StaffLayout>
      <div className='w-full h-full'>
        <div className="bg-opacity-75 rounded-2xl p-2 lg:mx-2">
          <div className='flex space-x-10 w-full px-3 py-2 rounded-2xl  bg-indigo-500'>
            <div id='student-basic' className=''>
              <div className='flex justify-center'>
                <ProfileAvatar user={staffInfo} size={20}/>
              </div>
              <div className='text-center'>
                <UserEditPhoto refreshUser={getStudentInfo} />
              </div>
            </div>
            <div className=''>
              <div className='my-2'>
                <p className='font-bold text-3xl text-white'>{staffInfo?.firstName} {staffInfo?.lastName}</p>
              </div>
            </div>
          </div>
                        {/* basic info */}
          <div id='student-profile' className='rounded-lg shadow-sm my-2 space-y-2 bg-white'>
            <div className='text-xl py-2 px-3 rounded-t-md bg-slate-200 font-semibold flex justify-between'>
              <p >Basic Information</p>
              {
                authState.role.includes("administrator")?              <button>
                  <PencilIcon className='w-6 h-6' />
                </button>
                :null
              }
            </div>

            <div className='lg:grid grid-cols-2 px-2'>
              <div id='column-one' className='col-span-1'>
                <div className='flex space-x-5 items-center px-3 py-2 '>
                  <p className='text-sm'>Email:</p>
                  <p className='font-semibold text-blue-500'>{staffInfo?.email}</p>
                </div>
                <div className='flex space-x-5 items-center px-3 py-2 '>
                  <p className='text-sm'>School: </p>
                  <p className='font-semibold text-blue-500'>{staffInfo?.school?.schoolName}</p>
                </div>
                <div className='flex space-x-5 items-center px-3 py-2 '>
                  <p className='text-sm'>Member Since:</p>
                  <p className='font-semibold text-blue-500'>
                    {moment(staffInfo?.createdAt).format('YYYY-MM-DD')}
                  </p>
                </div>
              </div>

              <div id='column-two' className='col-span-1'>
                <div className='flex space-x-5 items-center px-3 py-2 '>
                  <p className='text-sm'>Date of Birth:</p>
                  <p className='font-semibold text-blue-500'>
                  {
                    staffInfo?.dateOfBirth?
                    moment(staffInfo?.dateOfBirth).format('YYYY-MM-DD'):
                    "N/A"
                    }
                  </p>
                </div>

                <div className='flex space-x-5 items-center px-3 py-2 '>
                  <p className='text-sm'>Nationality:</p>
                  <p className='font-semibold text-blue-500'>
                    
                  </p>
                </div>
                <div className='flex space-x-5 items-center px-3 py-2 '>
                  <Link to={`/user/change-password`} className='px-2 py-1 bg-blue-500 rounded-lg text-white font-semibold text-sm'>Change Password</Link>
                </div>
              </div>
            </div>
          </div>
          <p className='text-xl py-1 px-3 rounded-t-md font-semibold flex justify-between'>Activities</p>
          <div className=" flow-root">
            <div className=" overflow-x-auto ">
              <div className="inline-block min-w-full py-2 align-middle">
                <div className="overflow-hidden ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6">
                        Event
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                        Date
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                        Attendance
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                        Event Type
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                    {events.map((event) => (
                      <tr key={event?.id}>
                        <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-bold text-blue-500 text-center sm:pl-6">
                           <Link to={`/school/events/${event?.id}`}>
                            {event?.eventName}
                           </Link>
                        </td>
                        <td className="whitespace-nowrap px-3 py-2 text-sm t font-semibold  text-center">
                          {moment(event?.eventDateTime).format('YYYY-MM-DD')}
                        </td>
                        <td className={`whitespace-nowrap px-3 py-2 font-semibold text-sm text-center`}>
                            {event?.invitations?.filter((invitation)=>{
                                return invitation?.status === "accepted"
                                }
                            ).length}
                        </td>
                        <td className="whitespace-nowrap px-3 text-sm text-gray-500 font-semibold ">
                            <p className='rounded-2xl bg-blue-500 text-white w-fit px-2 '>
                            {event?.eventCategory?.categoryName}
                            </p>
                        </td>
                      </tr>
                    ))}
                    </tbody>
                </table>
                </div>
              </div>
            </div>
          </div>
            {/* user information section */}

      </div>
      </div>
    </StaffLayout>
    </>
  )
}