import React, { createContext, useContext, useState } from 'react';
const SystemCornerWarningContext = createContext();

export const useCornerNotifications = () => {
    return useContext(SystemCornerWarningContext);
};

export const SystemCornerWarningProvider = ({ children }) => {

    const [banner, setBanner] = useState({ message: null, color: 'bg-yellow-400' });

    const showNotification = (message, color = 'bg-yellow-400') => {
        setBanner({ message, color });
        setTimeout(() => {
            setBanner({ message: null, color: 'bg-yellow-400' });
        }, 5000); // Adjust the timeout as needed
    };

    return (
        <SystemCornerWarningContext.Provider value={{ showNotification }}>
            {children}
            {
              banner.message 
              && <CornerNotification 
              message={banner.message} 
              color={banner.color}
              onClose={() => setBanner({ message: null, color: 'text-slate-800' })} 
              />}
        </SystemCornerWarningContext.Provider>
    );
};

const CornerNotification = ({ message, color, onClose }) => {
    return (
        <div className={`fixed bottom-10 flex justify-between left-4 w-96 px-3 py-3 text-start z-50 rounded-lg shadow-lg animate-slide-in bg-white`}>
            <div className={`flex items-center text-sm font-semibold ${color} break-words`}>
                {message}
            </div>
            <button onClick={onClose} className="text-xs text-slate-700 underline font-semibold">
                Close
            </button>
        </div>
    );
};