import React from 'react'

export default function ProfileAvatar({user,size,textSize}) {
    // const image64 = `data:image/png;base64,${user?.profilePicture}`;
    return (
    <>
    {
        user?.profilePicture? 
            <img
            className={`inline-block h-${size} w-${size} object-cover rounded-full shadow-md border-2 border-teal-50`}
            src={user?.profilePicture}           
            alt=""
            />
        :   // If there is no  picture, display the user's initials
            <div 
                className={`inline-block h-${size} w-${size} flex items-center justify-center bg-gray-200 rounded-full shadow-md border-2 border-teal-50 ${textSize} font-bold text-gray-700`}
                >
                {user?.firstName?.[0]} {user?.lastName?.[0]}
            </div>
    }
    </>
  )
}
