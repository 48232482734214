import React, { useContext, useState, Fragment, useRef} from 'react'
import axiosWithInterceptor from '../../../../hooks/axiosInterceptor';
import {AuthenticationContext } from '../../../../services/AuthContextModule';
import { useLocation,useNavigate} from "react-router-dom";
import { TrashIcon, ArchiveBoxXMarkIcon } from '@heroicons/react/20/solid'
import { Dialog, Transition } from '@headlessui/react';
import { useBanner } from '../../../../services/SystemBannerMessage';

export default function UnArchiveStudentButton({student}) {
    const [showModal, setShowModal] = useState(false);
    const [open, setOpen] = useState(false)
    const cancelButtonRef = useRef(null)

    const useAxiosWithInterceptor = axiosWithInterceptor();
    const {authState} = useContext(AuthenticationContext);
    const userId = authState.user;
    const schoolId = authState.schoolId;
    const navigateTo = useNavigate();
    const location = useLocation();

    const {showBanner} = useBanner();

    const [deleteConfirm, setDeleteConfirm] = useState('');
    
    const handleArchiveConfirm = (e)=>{
        setDeleteConfirm(e.target.value);
    }

    const handleShowModal = ()=>{
        // setShowModal(true)
        setOpen(true)
    }

    const handleCloseModal = ()=>{
        setShowModal(false)
    }

    const handleUnArchive = async()=>{
        await useAxiosWithInterceptor.put(`/api/user/${userId}/school/${schoolId}/administrator/students/${student.id}/unarchive`,{withCredentials:true})
        .then((response)=>{
            if(response.status === 200){
                navigateTo(`/school/students/${student.id}`,{state:{from: location},replace:true})
                showBanner(`${student.firstName} ${student.lastName} has been un-archived`,"bg-green-400")
                handleCloseModal();
            }

        })
        .catch((error)=>{
            console.log(error)
        })
    }
  return (
    <>
    <button
        type="button"
        onClick={handleShowModal}
        className="inline-flex items-center gap-x-1.5 rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
    >
      <ArchiveBoxXMarkIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
        UN-ARCHIVE {student?.firstName?.toUpperCase()} {student?.lastName?.toUpperCase()} 
    </button>
    <Transition.Root show={open} as={Fragment}>
       <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
           <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-3 pt-3 text-left shadow-xl transition-all sm:my-4 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="sm:flex sm:items-center">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        UnArchive Account
                        </Dialog.Title>
                        <div className="mt-2 text-left font-semibold text-sm">
                            <div className='mb-2'>
                                <p className="text text-red-500">You are about to un-Archive this student and all his/her records</p>
                            </div>
                            <div className='grid gap-y-1 ml-3'>
                                <p>{student.firstName} {student.lastName}</p>
                                <p>{student.email}</p>
                                <p>{student.school?.schoolName}</p>
                            </div>
                        </div>
                    </div>
                  </div>

                    <div className="mt-3 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                            onClick={handleUnArchive}>
                            UnArchive
                        </button>
                        <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            onClick={() => setOpen(false)}
                            ref={cancelButtonRef} >
                            Cancel
                        </button>
                    </div>
                </Dialog.Panel>
                </Transition.Child>
            </div>
          </div>
        </Dialog>
    </Transition.Root>
    </>
  )
}