import React, { useContext, useEffect, useState } from 'react'
import axiosWithInterceptor from '../../../../hooks/axiosInterceptor';
import { Link } from 'react-router-dom';
import { AuthenticationContext } from '../../../../services/AuthContextModule';
import SuperAdminLayout from '../components/SuperAdminLayout';

export default function SuperAdminContact() {
  return (
    <SuperAdminLayout>
    <div className='md:w-full w-full'>
      <div className='bg-opacity-75 rounded-2xl px-1 py-2 lg:mx-2'>
        <p>Super Administrator Contact page</p>
      </div>
    </div>
    </SuperAdminLayout>
  )
}
