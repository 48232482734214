import React,{useState,useEffect,useContext} from 'react'
import axiosWithInterceptor from '../../../../hooks/axiosInterceptor';
import { AuthenticationContext } from '../../../../services/AuthContextModule';
import { Link, useLocation,useNavigate,useParams } from "react-router-dom";
import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid';
import CountryPicker from '../../../components/formComponents/CountryPicker';
import SuperAdminLayout from '../components/SuperAdminLayout';

export default function SuperAdminCreateSchool() {

  const {authState} = useContext(AuthenticationContext);
  const useAxiosWithInterceptor = axiosWithInterceptor();
  const navigateTo = useNavigate();

  const [newSchoolInfo, setNewSchoolInfo] = useState({
    schoolName:"",
    profilePicture:"",
    foundedSince:"",
    address:"",
    city:"",
    state:"",
    country:"",
    emailDomain:"",
  })

  const handleNewSchoolInfo = (e)=>{
    setNewSchoolInfo({...newSchoolInfo,[e.target.name]:e.target.value});
  }

  const handleCountryChange = (selectedOption) => {
    setNewSchoolInfo({
        ...newSchoolInfo,
        country: selectedOption.value
    });
  }

  const handleCreateNewSchool = async()=>{
    let emptyField = [];
    Object.keys(newSchoolInfo).forEach((key)=>{
      if(newSchoolInfo[key] === ""|| newSchoolInfo[key]=== null){
        if(key === "profilePicture"){
          return;
        }
        emptyField.push(key);
      }
    });
    if(emptyField.length > 0){
      alert("Please fill out all fields");
      return;
    }
    await useAxiosWithInterceptor.post(`/api/super-admin/schools/new`,newSchoolInfo,{withCredentials:true})
    .then((response)=>{
      if(response.status === 200){
        const newSchoolId = response.data.payload.id;
        navigateTo(`/admin/schools/${newSchoolId}`,{replace:true})
      }
    }).catch((error)=>{
      console.log(error)
    })
  }

  return (
    <SuperAdminLayout>
      <div className='md:w-full w-full'>
      <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3 ">
        <div className="px-4 sm:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900">School Information</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600"></p>
        </div>

        <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
          <div className="px-4 py-6 sm:p-8">
              <div className="sm:col-span-3 my-2 ">
                <label htmlFor="schoolName" className="block text-sm font-medium leading-6 text-gray-900">
                  School Name
                </label>
                <input
                  type="text"
                  name="schoolName"
                  value={newSchoolInfo.schoolName}
                  onChange={handleNewSchoolInfo}
                  className="px-2 block sm:w-3/4 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 font-semibold"
                />
              </div>

              <div className="mt-2 justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-2 sm:w-3/4 w-full">
              <PhotoIcon className="mx-auto h-12 w-12  text-gray-300" aria-hidden="true" />
              <div className="text-center ">
                <label
                  htmlFor="file-upload"
                  className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500 "
                  >
                  <span>Profile Picture</span>
                  <input 
                    id="file-upload" 
                    name="file-upload" 
                    type="file" accept='.csv' className="sr-only sm:w-3/4 w-full " 
                      />
                </label>
              </div>
                
              </div>
              <div className="sm:col-span-4 my-2 ">
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                  Email Domain
                </label>
                <div className="mt-2">
                  <input
                    name="emailDomain"
                    type="email"
                    value={newSchoolInfo.emailDomain}
                    onChange={handleNewSchoolInfo}
                    className="px-2 block sm:w-3/4 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 font-semibold"
                  />
                </div>
              </div>

              <div className="sm:col-span-4 my-4">
                <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                  Country
                </label>
                <CountryPicker handleSelect={handleCountryChange} />
              </div>

              <div className="col-span-full my-2">
                <label htmlFor="street-address" className="block text-sm font-medium leading-6 text-gray-900">
                  Street address
                </label>
                <input
                  type="text"
                  name="address"
                  value={newSchoolInfo.address}
                  onChange={handleNewSchoolInfo}
                  className="px-2 block sm:w-3/4 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 font-semibold"
                />
              </div>

              <div className="sm:col-span-2 my-2">
                <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">
                  City
                </label>
                <input
                  type="text"
                  name="city"
                  value={newSchoolInfo.city}
                  onChange={handleNewSchoolInfo}
                  className="px-2 block sm:w-3/4 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 font-semibold"
                />
              </div>

              <div className="sm:col-span-2 my-2">
                <label htmlFor="region" className="block text-sm font-medium leading-6 text-gray-900">
                  State / Province
                </label>
                <input
                  type="text"
                  name="state"
                  value={newSchoolInfo.state}
                  onChange={handleNewSchoolInfo}
                  className="px-2 block sm:w-3/4 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 font-semibold"
                />
              </div>

              <div className="sm:col-span-2 my-2">
                <label htmlFor="postal-code" className="block text-sm font-medium leading-6 text-gray-900">
                  founded Since
                </label>
                <input
                  type="text"
                  name="foundedSince"
                  value={newSchoolInfo.foundedSince}
                  onChange={handleNewSchoolInfo}
                  className="px-2 block sm:w-3/4 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 font-semibold"
                />
              </div>
              <div className="sm:col-span-2 mt-8 mb-4">
                <button 
                  onClick={handleCreateNewSchool}
                  className='transition ease-in-out delay-50 px-5 py-2 bg-blue-500 rounded font-semibold text-white hover:bg-blue-600'>Create School</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SuperAdminLayout>
  )
}
