import React, { useContext, useEffect, useState,useCallback } from 'react'

import UnAuthLayout from './components/UnAuthLayout';
import Layout from './components/Layout';
import axiosWithInterceptor from '../hooks/axiosInterceptor';
import { AuthenticationContext } from '../services/AuthContextModule';
import { Link,useNavigate } from 'react-router-dom';

export default function UserPendingActivationSetup() {
  const useAxiosWithInterceptor = axiosWithInterceptor();
  const {authState} = useContext(AuthenticationContext);
  const userId = authState.user;

  const sendActivationEmail = async()=>{
    // send activation email
    await useAxiosWithInterceptor.get(`/api/user/${userId}/send-activation-email`,{withCredentials:true})
    .then((response)=>{
      if(response.status === 200){
        console.log(response.data.payload)
      }
    })
    .catch((error)=>{
      console.log(error)
    })
  }

  return (
    <>
    <Layout>
      <div className='flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8'>
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              <p>Please follow the link in activation email to activate your account</p>
            </h2>
          </div>
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px] flex justify-center">
            <button 
              onClick={sendActivationEmail}
              className='px-3 py-2 rounded bg-green-500 text-white font-semibold'>Resend Activation Email</button>
          </div>
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px] flex justify-center">
            <p>if you have not received an activation email or you are not able to find it, please contact your administrator</p>
          </div>
      </div>
    </Layout>
    </>
  )
}
