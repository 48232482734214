import React, { useState, useEffect } from 'react';

export default function Student_SingleEventFeedStatus({ eventObject, userId }) {
    const [isIncluded, setIsIncluded] = useState("");

    useEffect(() => {
        const handleIsIncluded = () => {
            const isInvited = eventObject.invitations.some((invitation) => {
                return invitation.student.id === userId;
            });

            if (isInvited) {
                const invitation = eventObject.invitations.find((invitation) => {
                    return invitation.student.id === userId;
                });

                if (invitation.status === "accepted") {
                    setIsIncluded("Accepted");
                } else if (invitation.status === "declined") {
                    setIsIncluded("Declined");
                } else if (invitation.status === "pending") {
                    setIsIncluded("Pending");
                }
            } else {
                setIsIncluded("Not On List");
            }
        };

        handleIsIncluded();
    }, [eventObject, userId]);

    return (
        <>
            {isIncluded === "Accepted" && (
                <p className='px-3 py-1 rounded-2xl shadow-sm font-semibold bg-white text-green-500 border-2 border-green-500 '>
                    {isIncluded}
                </p>
            )}
            {isIncluded === "Declined" && (
                <p className='px-3 py-1 rounded-2xl font-semibold bg-white text-red-500 border-2 border-red-500 shadow-sm'>
                    {isIncluded}
                </p>
            )}
            {isIncluded === "Pending" && (
                <p className='px-3 py-1 rounded-2xl font-semibold bg-white text-amber-500 border-2 border-amber-500  shadow-sm'>
                    {isIncluded}
                </p>
            )}
            {isIncluded === "Not On List" && (
                null
            )}
        </>
    );
}
